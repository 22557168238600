/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useFetch, useLocalStorage, useUpdate } from "@marketpartner/mp-common-react"
import { FC, useEffect, useState } from "react"
import { useAuthenticationContext } from '../common/authentication/AuthenticationContext'
import { Button } from '../common/Button'
import { Checkboxes } from '../common/form/fields/Checkboxes'
import { Loading } from "../common/loading/Loading"
import { LoadingSpinner } from '../common/loading/LoadingSpinner'
import { P } from '../common/typography'
import { useTheme } from '../ThemeSelector'
import { HoldingPoll } from './HoldingPoll'
import { PollHeadings } from './PollHeading'
import { Poll, pollingApi } from "./pollingApi"

export const Polling = () => {
    const pollingRequest = useFetch(
        () => pollingApi.load(),
        [],
        {
            reloadFrequency: 4000
        }
    )
    return <Loading
        request={pollingRequest}
        render={poll => <>
            {poll ? <LoadedPoll poll={poll} /> : <P>Polling will be available during the event.</P>}
        </>}
        reloadMode={'static'}
    />
}

type LoadedPollingProps = {
    poll: Poll
}

const containerStyle = css`
    padding-top: 20px;
`

export const LoadedPoll: FC<LoadedPollingProps> = ({ poll }) => {

    const theme = useTheme()

    const { mpt } = useAuthenticationContext()

    const [selectedResponses, setSelectedResponses] = useLocalStorage<string[]>(`${poll.id}-responses`, [])
    const maxResponses = poll ? poll.maxResponses : 0

    const [pollAnswered, setPollAnswered] = useLocalStorage(`${poll.id}-answered`, false)
    const [error, setError] = useState('')

    const pollSubmitRequest = useUpdate({
        update: () => {
            setError('')
            return Promise.all(selectedResponses.map(response => pollingApi.post({
                id: response,
                pollId: poll.id
            }, mpt)))
        },
        onSuccess: () => setPollAnswered(true),
        onError: () => setError('An error occurred, please try again')
    })

    useEffect(() => {
        if (selectedResponses.length > maxResponses) {
            setSelectedResponses(prevItems => prevItems.slice(0 - maxResponses))
        }
    }, [selectedResponses.length, setSelectedResponses, maxResponses])

    useEffect(() => {
        setError('')
    }, [poll.id])


    if (!poll) return <>No poll found</>

    if (poll.responses.length === 0) return <HoldingPoll poll={poll} />

    return <div css={containerStyle}>

        {pollSubmitRequest.isLoading && <LoadingSpinner absolute={true} />}

        {error && <P css={css`color: ${theme.form.message.error};`}>{error}</P>}
        {pollAnswered && <P css={css`color: ${theme.form.message.success.color};`}>Your response has been submitted.</P>}

        <PollHeadings heading={poll.heading} subheading={poll.subheading} />

        <Checkboxes
            choices={poll.responses.map(response => ({
                label: response.text,
                value: response.value
            }))}
            label={poll.text}
            required={false}
            setValue={setSelectedResponses}
            value={selectedResponses}
            disabled={pollAnswered}
            maxResponses={poll.maxResponses}
        />

        {!pollAnswered &&
            <Button
                onClick={pollSubmitRequest.trigger}
                disabled={pollSubmitRequest.isLoading || pollAnswered}
            >
                Submit poll
            </Button>
        }
        {/* </>} */}

    </div>
}

