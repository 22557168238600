import { Registration } from "@marketpartner/backend-api"
import { useFetch } from "@marketpartner/mp-common-react"
import { createContext, FC, ReactNode, useContext } from "react"
import { useBackend } from "../hooks/useBackend"
import { Loading } from "../loading/Loading"
import { useUrlParametersContext } from "../UrlParametersContext"
import { eventDetails } from "../../eventDetails"
import { useTokenContext } from "./TokenContext"

type RegistrationDetails = {
    registration: Registration
    mpt: string
}

type AuthenticationContextValue = RegistrationDetails

const context = createContext<AuthenticationContextValue>(undefined!)
export const useAuthenticationContext = () => useContext(context)

export const AuthenticationContext: FC<{children: ReactNode}> = ({ children }) => {

    const { clientId, eventId } = eventDetails
    const { mpt, clearMpt } = useTokenContext()
    const { removeMptFromUrl } = useUrlParametersContext()
    const backend = useBackend()

    const authenticationRequest = useFetch(
        async (): Promise<RegistrationDetails> => {
            let registration
            try {
                registration = await backend.registration().getOwnRegistration(clientId, eventId)
            } catch (err: any) {
                if ('response' in err) {
                    const status = err.response.status
                    if (status === 403) {
                        clearMpt()
                        removeMptFromUrl()
                    }
                }
                throw err
            }
            return {
                registration: registration,
                mpt: mpt as string
            }
        },
        []
    )

    return <Loading 
        request={authenticationRequest}
        render={data => <context.Provider 
            value={data}
            children={children}
        />}
    />
    
}