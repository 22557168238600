import { useFetch, useLocalStorage, useUpdate } from "@marketpartner/mp-common-react"
import { createContext, FC, ReactNode, useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useBackend } from "../hooks/useBackend"
import { eventDetails } from "../../eventDetails"


type ActivitiesContextValue = {
    logClick: (value: string) => void
    logViewAgendaDay: (day: string) => void
    logViewResource: (name: string) => void
    logEventAction: (action: string) => Promise<void>
    logDownload: (fileName: string) => void
}
const context = createContext<ActivitiesContextValue>(undefined!)

export const useActivitiesContext = () => useContext(context)

export const ActivitiesContext:FC<{children: ReactNode}> = ({ children }) => {

    const { clientId, eventId } = eventDetails
    const backend = useBackend()
    const location = useLocation()

    const [actions, setActions] = useLocalStorage<Record<string, number[]>>('actions',{})
    const [tmpActions, setTmpActions] = useState<Record<string, number[]>>({})

    const postActivitiesRequest = useUpdate({
        update: () => {
            setTmpActions(actions)
            setActions({})
            return backend.activities().recordActivities(clientId, eventId, {
                actionOffsets: actions,
                pollRate: 15,
                timedActivities: []
            })
        },
        onError: () => {
            setActions(prevActions => {
                const combinedActions = {...prevActions}
                for (const actionName in tmpActions) {
                    combinedActions[actionName] = actionName in prevActions
                        ? [...prevActions[actionName], ...tmpActions[actionName]]
                        : [...tmpActions[actionName]]
                }
                return combinedActions
            })
            setTmpActions({})
        },
        onSuccess: () => setTmpActions({})
    })
    useFetch(() => postActivitiesRequest.trigger(), [], {
        reloadFrequency: 15000
    })
    function logAction(action: string) {
        setActions(prevActions => ({
            ...prevActions,
            [action]: action in prevActions
                ? [...prevActions[action], 0]
                : [0]
        }))
    }
    function logPageLoad(page: string) {
        logAction(`CAPageLoad:${page}`)
    }

    async function logEventAction(action: string) {
        const actionName = `Activity:${action}`
        try {
            await backend.activities().recordActions(clientId, eventId, {
                actionOffsets: {
                    [actionName]: [0]
                }

            })
        } catch (error) {
            logAction(actionName)
        }
    }

    useEffect(() => {
        const page = location.pathname === '/' ? 'home' : location.pathname.replace('/','')
        logPageLoad(page)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])
    return <context.Provider value={{
        logClick: (value) => logAction(`Click:${value}`),
        logViewAgendaDay: (value) => {
            logAction(`ViewAgendaDay:${value}`)
        },
        logViewResource: (name) => {
            logAction(`ViewResource:${name}`)
        },
        logEventAction,
        logDownload: (fileName: string) => {
            logAction(`Download:${fileName}`)
        }
    }}>
        { children }
    </context.Provider>
}