import { useFetch, DynamicFormState } from "@marketpartner/mp-common-react"
import { Form, PublicForm } from "@marketpartner/backend-api"
import { FC } from "react"
import { DynamicForm } from "../common/form/DynamicForm"
import { useBackend } from "../common/hooks/useBackend"
import { Loading } from "../common/loading/Loading"
import { eventDetails } from "../eventDetails"

const { clientId, eventId } = eventDetails

export const WordCloud = () => {

    const backend = useBackend()

    const wordcloudFormRequest = useFetch(async () => {
        const forms = await backend.forms().getAll(clientId, eventId)
        const wordCloudForm = forms.find(form => form.name.toLowerCase() === 'word cloud')
        if (!wordCloudForm) {
            const error = new Error("Word cloud form not set up")
            throw error
        }
        return wordCloudForm
    }, [], {
        reloadFrequency: 60000
    })

    return <Loading 
        request={wordcloudFormRequest} 
        render={data => <>
            <LoadedWordCloud form={data} />
        </>} 
        reloadMode="show-old-data"
    />
}

type LoadedWordCloudProps = {
    form: Form | PublicForm
}

const LoadedWordCloud: FC<LoadedWordCloudProps> = ({ form }) => {

    const backend = useBackend()

    const onSubmit = (formState: DynamicFormState) => {
        return backend.forms().responses().insert(clientId, eventId, form.id, {
            formData: formState.values
        })
    }

    return <DynamicForm onSubmit={onSubmit} form={form} submitButtonValue="Submit" successMessage="Thank you for your submission." />
}