/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC } from "react"
import { TextField } from "../common/form/fields/TextField"
import { useTheme } from '../ThemeSelector'
type SearchContactsProps = {
    search: string
    setSearch: (search: string) => void
}
export const SearchContacts: FC<SearchContactsProps> = ({
    search,
    setSearch
}) => {

    const theme = useTheme()

    const searchContainerStyle = css`
        position: relative;
    `
    const searchStyle = css`
        & input {
            border: none;
            border-bottom: 1px solid ${theme.delegates.search.borderColor};
        }
    `

    const searchCloseStyle = css`
        position: absolute;
        right: 10px;
        top: 20px;
        cursor: pointer;
    `

    return <div css={searchContainerStyle}>
        <TextField
            label=''
            placeholder='Search'
            required={false}
            value={search}
            setValue={setSearch}
            css={searchStyle}
        />
        { search && <div css={searchCloseStyle} onClick={() => setSearch('')}>&#10005;</div> }
    </div>
}
