import { NetworkingProfile } from "@marketpartner/backend-api"
import { useFetch } from "@marketpartner/mp-common-react"
import { FC, useState } from "react"
import { useBackend } from "../common/hooks/useBackend"
import { ListItem } from "../common/ListItem"
import { Loading } from "../common/loading/Loading"
import { matchDelegate, sortDelegates } from "../delegates/LoadedDelegates"
import { SearchContacts } from "../delegates/SearchContacts"
import { eventDetails } from "../eventDetails"
import { NetworkingUser } from "./NetworkingUser"
type NetworkingProps = {
    profile: NetworkingProfile
}
// TODO: Add categories
const { clientId, eventId } = eventDetails
export const Networking: FC<NetworkingProps> = ({
    profile
}) => {

    const backend = useBackend()

    const allUsersFetch = useFetch(
        () => backend.networking().getAll(clientId, eventId),
        []
    )
    return <Loading 
        request={allUsersFetch}
        render={users => <LoadedNetworking 
            profile={profile}
            users={users}
        />}
    />
}


type LoadedNetworkingProps = {
    profile: NetworkingProfile
    users: NetworkingProfile[]
}
export const LoadedNetworking: FC<LoadedNetworkingProps> = ({
    profile,
    users
}) => {
    const [search, setSearch] = useState('')
    return <div>
        <SearchContacts
            search={search}
            setSearch={setSearch}
        />
        { users
            .filter(user => matchDelegate(user, search))
            .sort(sortDelegates)
            .map(user => <ListItem
                key={user.id}
            >
                <NetworkingUser 
                    user={user}
                    isCurrentUser={user.id === profile.id}
                />
            </ListItem>)}
    </div>
}
