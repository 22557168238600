import { createContext, useContext, useRef, useEffect, useState, FC, ReactNode, RefObject } from "react"
import { useLocation } from "react-router-dom"
import { useNavContext } from "./navigation/NavContext"

type ScrollContextValue = {
    containerRef: RefObject<HTMLDivElement>
    onSetScrollTo: (id: string | undefined) => void
}

const context = createContext<ScrollContextValue>(undefined!)

export const useScrollContext = () => useContext(context)

export const ScrollContext: FC<{ children: ReactNode }> = ({ children }) => {

    const [scrollTo, setScrollTo] = useState<string>()
    const containerRef = useRef<HTMLDivElement>(null)
    const location = useLocation()
    const navContext = useNavContext()
    const setNavOpen = navContext.setNavOpen

    useEffect(() => {
        if (scrollTo) {
            const el = document.getElementById(scrollTo)
            if (el) {
                el.scrollIntoView({ behavior: "smooth" })
            } else {
                console.warn(`Can't find element with ID: ${scrollTo}`)
            }
        } else {
            if (containerRef.current) {
                containerRef.current.scrollTo({top: 0, behavior: 'smooth'})
            }
        }
        setNavOpen(false)
    }, [location, scrollTo, setNavOpen])

    function onSetScrollTo(id: string | undefined) {
        setScrollTo(id)
    }
    
    return <context.Provider value={{
        containerRef,
        onSetScrollTo
    }}>
        { children }
    </context.Provider>

}