import { Button } from "./Button"
import { LinkElement } from "./LinkElement"
import { FC, ReactNode } from "react"

type LinkButtonProps = {
    to: string
    external?: boolean
    scrollTo?: string
    className?: string
    children?: ReactNode
    onClick?: () => void
}

export const LinkButton: FC<LinkButtonProps> = ({ 
    to, 
    external, 
    scrollTo,
    children,
    onClick,
    ...props
}) => {

    return <LinkElement 
        to={to}
        scrollTo={scrollTo}
        external={external}
        onClick={onClick}
    >
        <Button {...props}>
            { children }
        </Button>
    </LinkElement>
}