import { createContext, FC, ReactNode, useContext, useEffect, useState } from "react";
import { useEventLocalStorage } from "./hooks/useEventLocalStorage";
import { LoadingSpinner } from "./loading/LoadingSpinner";

type UrlParametersContextValue = {
    urlParameters: Record<string, string>
    removeMptFromUrl: () => void
}

const tempQueryParamKeys = ['mpp']

const context = createContext<UrlParametersContextValue>(undefined!)
export const useUrlParametersContext = () => useContext(context)

export const UrlParametersContext: FC<{children: ReactNode}> = ({ children }) => {

    const [lsTempUrlParams, setLsTempUrlParams, deleteLsTempUrlParams] = useEventLocalStorage<[string, string][]>('urlParameters', [])
    const [urlParameters, setUrlParameters] = useState({})
    const [loadChildren, setLoadChildren] = useState(false)
    
    //any url parameters from tempQueryParamKeys are temporarily stored in LS and removed from the url – making the app reload
    //the params from the temporary LS are combined with the the other query params and stored in state
    //temporary LS is then deleted
    useEffect(() => {

        const queryParams = getQueryParamsTuples()
        const tempQueryParams = queryParams.filter(param => tempQueryParamKeys.includes(param[0]))
        const newQueryParams = queryParams.filter(param => !tempQueryParamKeys.includes(param[0]))

        if (tempQueryParams.length) {

            setLsTempUrlParams(tempQueryParams)

            const newUrl = getUrlWithoutParams(tempQueryParamKeys)
            window.location.replace(newUrl)

            return

        }

        setUrlParameters({
            ...Object.fromEntries(lsTempUrlParams),
            ...Object.fromEntries(newQueryParams)
        })
        deleteLsTempUrlParams()

        setLoadChildren(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    function removeMptFromUrl() {
        const newUrl = getUrlWithoutParams(['mpt'])
        window.location.replace(newUrl)
    }

    return <context.Provider value={{
        urlParameters,
        removeMptFromUrl
    }}>
        { loadChildren ? children : <LoadingSpinner absolute={true} />}
    </context.Provider>
}

function getUrlWithoutParams(unwantedParamKeys: string[]): string {
    const oldUrl = window.location.href
    const queryParamTuples = getQueryParamsTuples()
    if (!queryParamTuples.length) return oldUrl
    const filteredQueryParamTuples = queryParamTuples
        .filter(paramTuple => !unwantedParamKeys.includes(paramTuple[0]))
    const newQueryString = getQueryStringFromTuples(filteredQueryParamTuples)
    // if (!newQueryString) return oldUrl
    return oldUrl.replace(window.location.search, `?${newQueryString}`)
}

function getQueryParamsTuples(): [string, string][] {
    const queryString = window.location.search.replace("?","")
    if (!queryString) {
        return []
    }
    const queryParamsArr = queryString.split("&")
    return queryParamsArr
        .map(splitParam)
}

function splitParam(param: string): [string, string] {
    const splitParam = param.split("=")
    return [splitParam[0], splitParam[1] ?? '']
}

function getQueryStringFromTuples(tuples: [string, string][]): string {
    return tuples
        .map(param => param.join('='))
        .join('&')
}