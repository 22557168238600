/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { LinkElement } from '../LinkElement'
import { FC, ReactNode } from "react"
import { useTheme } from '../../ThemeSelector'

export type Tab = {
    label: string
    path: string
    element: ReactNode
}

type TabsProps = {
    tabs: Tab[]
}

export const Tabs: FC<TabsProps> = ({ tabs }) => {

    const theme = useTheme()

    const tabsStyle = css`
        display: flex;
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        gap: 10px;
        justify-content: space-evenly;
        padding-bottom: 10px;
        flex: 0 0 auto;
        padding: 20px 20px 10px;
        flex-wrap: wrap;
    `

    const tabStyle = css`
        color: ${theme.tab.color};
        padding: 0 5px 3px;
        font-size: 15px;
        &.is_selected {
            border-bottom: 2px solid ${theme.tab.borderColorActive};
        }
    `
    return <div css={tabsStyle}>
        {tabs.map(tab => <LinkElement 
            css={tabStyle} 
            key={tab.label} 
            to={tab.path}
        >{tab.label}</LinkElement>)}
    </div>
}