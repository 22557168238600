import { find, NetworkingProfile } from "@marketpartner/backend-api"
import { useFetch } from "@marketpartner/mp-common-react"
import { FC } from "react"
import { useBackend } from "../common/hooks/useBackend"
import { Loading } from "../common/loading/Loading"
import { eventDetails } from "../eventDetails"
import { Networking } from "./Networking"
import { NetworkingForm } from "./NetworkingForm"

const { clientId, eventId } = eventDetails

export const NetworkingSignUp = () => {
    const backend = useBackend()

    const profileFetch = useFetch(
        () => find(backend.networking().getOwnProfile(clientId, eventId)),
        []
    )

    return <Loading
        request={profileFetch}
        render={(data) => <LoadedNetworkingSignUp
            profile={data}
            reload={profileFetch.reload}
        />}
    />
}

type LoadedNetworkingSignUpProps = {
    profile: NetworkingProfile | null
    reload: () => void
}

const LoadedNetworkingSignUp: FC<LoadedNetworkingSignUpProps> = ({
    profile,
    reload
}) => {

    return profile 
        ? <Networking profile={profile} />
        : <NetworkingForm onSuccess={reload} />

}