/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { TextConfig } from "@marketpartner/backend-api"
import { NonFieldComponent } from "@marketpartner/mp-common-react"
import { FC } from "react"
import { useTheme } from '../../../ThemeSelector'

type TextProps = {
    text: string
}
export const Text: FC<TextProps> = ({ text }) => {

    const theme = useTheme()

    const containerStyle = css`
        font-size: 1rem;
        margin-bottom: 10px;
        padding-bottom: 5px;
        border-bottom: ${theme.form.fields.borderColor};
        color: ${theme.form.fields.color};
    `

    return <div css={containerStyle}>{ text }</div>
}

export const DynamicText: NonFieldComponent<TextConfig> = ({
    config
}) => {
    return <Text 
        text={config.text}
    />
}