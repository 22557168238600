import { find, RegistrationForm } from "@marketpartner/backend-api"
import { useFetch } from "@marketpartner/mp-common-react"
import { FC } from "react"
import { useBackend } from "../common/hooks/useBackend"
import { Loading } from "../common/loading/Loading"
import { eventDetails } from "../eventDetails"
import { LoadedRegistration } from "./LoadedRegistration"

type ErrorWithStatus = Error & {
    code: number
}

const { clientId, eventId } = eventDetails

type RegFormProps = {
}


export const RegForm:FC<RegFormProps> = () => {

    const backend = useBackend()

    const registrationFormFetch = useFetch(
        async (): Promise<RegistrationForm> => {
            const companionAppForm = await find(backend.forms().getForCategory(clientId, eventId, 'companion_app'))
            if (companionAppForm) return companionAppForm
            const delegateForm = await find(backend.forms().getForCategory(clientId, eventId, 'delegate'))
            if (!delegateForm) {
                const error = new Error("Form not configured") as ErrorWithStatus
                error.code = 404
                throw error
            }
            return delegateForm
        }, []
    )
    return <Loading
        request={registrationFormFetch}
        render={form => <LoadedRegistration
            form={form}
        />}
    />
}