/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC } from "react"
import { ListItem } from "../common/ListItem"
import { usePopupContext } from '../common/popup/PopupContext'
import { useTheme } from "../ThemeSelector"
import { ReactComponent as PlayIcon } from './play-icon.svg'
import { ReactComponent as FileIcon } from './file-icon.svg'
import { ResourceWithMedia } from '../common/event-content/useEventContentFetch'

type ResourceRowProps = {
    resource: ResourceWithMedia
}

export const ResourceRow: FC<ResourceRowProps> = ({
    resource
}) => {

    const theme = useTheme()

    const containerStyle = css`
        width: 100%;
    `

    const topRowStyle = css`
        width: 100%;
        display: flex;
        justify-content: space-between;
        color: ${theme.list.item.color};
    `

    const categoryStyle = css`
        color: ${theme.list.item.colorAccent};
    `

    const actionsStyle = css`
        align-items: center;
        display: flex;
    `

    const iconStyle = css`
        height: 15px; 
        width: 15px;
        margin-right: 5px;
        transition: fill .1s;
        fill: ${theme.list.item.action.fill};
    `

    const detailsStyle = css`
        flex: 1 1 auto;
        color: ${theme.list.item.color};
        margin-top: 10px;
        padding-right: 10px;
    `

    const nameStyle = css`
        font-weight: 500;
    `

    const chevronStyle = css`
        border-style: solid;
        border-width: 1px 1px 0 0;
        border-color: ${theme.list.item.chevron.color};
        display: inline-block;
        height: 6px;
        transform: rotate(45deg);
        margin-right: 10px;
        width: 6px;
        transition: border-color .1s;
    `

    const { showPopup } = usePopupContext()

    function onShowPopup() {
        showPopup({
            type: "resource",
            data: resource
        })
    }

    return <ListItem
        onClick={onShowPopup}
    >
        <div css={containerStyle}>
            <div css={topRowStyle}>
                <div css={categoryStyle}>

                    { resource.categoryTitle }
                </div>
                <div css={actionsStyle} className="actions">
                    { resource.mediaType === 'vimeo' && <PlayIcon className="icon" css={iconStyle} />}
                    { resource.mediaType === 'file' && <FileIcon className="icon" css={iconStyle} />}
                    <div className="chevron" css={chevronStyle} />
                </div>
            </div>
            <div css={detailsStyle}>
                <div css={nameStyle}>
                    { resource.name }
                </div>
            </div>
            
        </div>
    </ListItem>
}