import { useFetch, DynamicFormState } from "@marketpartner/mp-common-react"
import { Form, PublicForm } from "@marketpartner/backend-api"
import { FC } from "react"
import { DynamicForm } from "../common/form/DynamicForm"
import { useBackend } from "../common/hooks/useBackend"
import { Loading } from "../common/loading/Loading"
import { eventDetails } from "../eventDetails"

const {clientId, eventId} = eventDetails

export const Questions = () => {

    const backend = useBackend()

    const questionFormRequest = useFetch( async () => {
        const forms = await backend.forms().getAll(clientId, eventId)
        const questionForm = forms.find(form => form.name === 'Questions')
        if (!questionForm) {
            const error = new Error("Question form not set up")
            throw error
        }
        return questionForm
    }, [])

    return <Loading request={questionFormRequest} render={data => <>
        <LoadedQuestions form={data} />
    </>} />

}

type LoadedQuestionsProps = {
    form: Form | PublicForm
}

const LoadedQuestions: FC<LoadedQuestionsProps> = ({form}) => {

    const backend = useBackend()

    const onSubmit = (formState: DynamicFormState) => {
        return backend.forms().responses().insert(clientId, eventId, form.id, {
            formData: formState.values
        })
    }

    return <DynamicForm 
        onSubmit={onSubmit} 
        form={form} 
        submitButtonValue="Submit" 
        successMessage="Thank you for submitting your question." 
    />
}