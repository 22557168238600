import { FC, useEffect } from "react"
import { useEventContentContext } from "../common/event-content/EventContentContext"
import { Loading } from "../common/loading/Loading"
import { LoadedResources } from "./LoadedResources"

export const Resources:FC = () => {

    const { eventContentRequest } = useEventContentContext()

    useEffect(() => {
        eventContentRequest.reload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return <Loading
        request={eventContentRequest} 
        render={({resourcesData}) => 
            <LoadedResources 
                resources={resourcesData.resources} 
                categories={resourcesData.categories}
            />
        }
        reloadMode="show-old-data"
    />
}