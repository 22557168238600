/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC, useState } from "react"
import { Button } from '../common/Button'
import { ResourceWithMedia } from '../common/event-content/useEventContentFetch'
import { LinkButton } from '../common/LinkButton'
import { VimeoPlayer } from '../common/player/VimeoPlayer'
import { H2, H3 } from '../common/typography'
import { useTheme } from "../ThemeSelector"
import { useActivitiesContext } from '../common/activities/ActivitiesContext'
import { useAuthenticationContext } from '../common/authentication/AuthenticationContext'
import { eventDetails } from '../eventDetails'
import { useUpdate } from '@marketpartner/mp-common-react'
import { emailResource } from './resourcesMailer'
import { LoadingSpinner } from '../common/loading/LoadingSpinner'

type ResourcePopupProps = {
    resource: ResourceWithMedia
}
export const ResourcePopup: FC<ResourcePopupProps> = ({
    resource
}) => {

    const theme = useTheme()
    const { logEventAction, logDownload } = useActivitiesContext()
    const { mpt } = useAuthenticationContext()
    const { clientId, eventId } = eventDetails
    const [emailSent, setEmailSent] = useState(false)
    const [error, setError] = useState('')

    const containerStyle = css`
        text-align: center;
    `

    const headingStyle = css`
        text-align: center; 
        color: ${theme.popup.heading.color};
    `

    const buttonStyle = css`
        font-weight: 500;
        margin: 10px 10px;
    `

    const emailRequest = useUpdate({
        update: async () => {
            setEmailSent(false)
            setError('')
            return emailResource(mpt, resource.entity_token, clientId, eventId)
        },
        onError: () => {
            setError('An error occurred, please try again')
        },
        onSuccess: () => {
            setEmailSent(true)
            logDownload(resource.name)
            logEventAction('resources')
        }
    })

    return <div css={containerStyle}>
        { emailRequest.isLoading && <LoadingSpinner 
            absolute={true}
        />}
        <H3 css={headingStyle}>{ resource.name }</H3>
        { resource.mediaType === 'vimeo' &&  <VimeoPlayer 
            source={resource.url}
            css={css`margin-top: 40px;`}
        />}
        { resource.mediaType === 'file' &&  <div 
        >
            <H2 css={css`margin: 30px 0;`}>Download this file in your browser or have it emailed to you.</H2>
            { emailSent && <H2 css={css`color: ${theme.form.message.success.color};`}>This resource has been emailed to you.</H2>}
            { error && <H2 css={css`color: ${theme.form.message.error.color};`}>An error occurred, please try again.</H2>}
            <LinkButton css={buttonStyle} to={resource.url} onClick={() => {
                logDownload(resource.name)
                logEventAction('resources')
            }} external={true}>Download file</LinkButton>
            <Button css={buttonStyle} onClick={emailRequest.trigger}>Email me this file</Button>
        </div>}
    </div>
}