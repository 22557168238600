/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC } from "react"
import { Button } from '../Button'
import { P } from "../typography"
type LoadingErrorProps = {
    error: any
    onRetry?: () => void
}
const containerStyle = css`
`
const pStyle = css`
`
export const LoadingError:FC<LoadingErrorProps> = ({ error, onRetry }) => {
    const errorObject = typeof error?.JSON === 'function' ? error?.toJSON() : error
    const matchingError = errors.find(it => it.code === errorObject?.status)
    const errorMessage = matchingError ? matchingError.message : errorObject?.message
    return <div css={containerStyle}>
        { errorMessage 
            ? <P css={pStyle}>{ errorMessage }</P> 
            : <>
                <P css={pStyle}>An error occurred.</P>
                <P css={pStyle}>Please ensure you are connected to the internet and that your browser is not using private browsing mode</P> 
                <P css={css`margin-top: 20px;`}>
                    { onRetry && <Button
                        className="button button--highlight"
                        onClick={onRetry}
                    >Try again</Button> }
                </P>
            </>
        }
    </div>
}
const errors = [
    {
        code: 400,
        message: 'Bad Request'
    },
    {
        code: 401,
        message: 'Unauthorized'
    },
    {
        code: 403,
        message: 'You are using an invalid link'
    },
    {
        code: 409,
        message: 'Conflict'
    },
    {
        code: 500,
        message: 'Internal Server Error'
    },
    {
        code: 502,
        message: 'Bad Gateway'
    },
    {
        code: 503,
        message: 'Service Unavailable'
    },
    {
        code: 504,
        message: 'Gateway Timeout'
    }
]