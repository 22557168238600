/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FlatEpCompany } from "@marketpartner/backend-api"
import { FC } from "react"
import { H3 } from '../common/typography'
import { useTheme } from '../ThemeSelector'
import parse from "html-react-parser"
import { SponsorLogo } from './SponsorLogo'
import { LinkElement } from '../common/LinkElement'

type SponsorPopupProps = {
    sponsor: FlatEpCompany
}

export const SponsorPopup: FC<SponsorPopupProps> = ({
    sponsor
}) => {

    const theme = useTheme()

    const containerStyle = css`
        text-align: center;
    `

    const headingStyle = css`
        text-align: center; 
        color: ${theme.popup.heading.color};
        margin-top: 20px;
    `

    const profileStyle = css`
        text-align: left;
        color: ${theme.popup.color};
    `

    const websiteStyle = css`
        margin-top: 10px;
    `

    const linkStyle = css`
        color: ${theme.app.link.color};
    `


    return <div css={containerStyle}>
        <SponsorLogo
            src={sponsor.logo_cropped}
            width="100%"
            height="60px"
            css={css``}
            vAlign="center"
        />
        <H3 css={headingStyle}>{ sponsor.name }</H3>
        <div css={profileStyle}>{ parse(sponsor.profile) }</div>
        { sponsor.website && <div css={websiteStyle}>
            <LinkElement 
                to={sponsor.website} 
                external={true}
                css={linkStyle}
            >Find out more &gt;</LinkElement>
        </div>}
    </div>

}