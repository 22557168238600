/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { TextareaConfig } from '@marketpartner/backend-api'
import { FieldComponent } from '@marketpartner/mp-common-react'
import { FC } from "react"
import { useTheme } from '../../../ThemeSelector'
import { FieldError } from '../FieldError'

type TextareaProps = {
    value: string
    required: boolean
    placeholder: string
    setValue: (value: string) => void
    label: string
    errors?: string[]
    className?: string
}

const containerStyle = css`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    font-size: 16px;
    margin-bottom: 20px;
`

export const Textarea: FC<TextareaProps> = ({ 
    value, 
    required, 
    setValue, 
    placeholder, 
    label,
    errors,
    ...props
}) => {

    const theme = useTheme()

    const labelStyle = css`
        padding: 0 0 10px;
        font-weight: 500;
        color: ${theme.form.fields.color};
    `

    const inputStyle = css`
        border: 1px solid ${theme.form.fields.borderColor};
        border-radius: 0;
        background: ${theme.form.fields.backgroundColor};
        outline: none;
        min-height: 120px;
        width: 100%;
        padding: 10px;
        font-size: 15px;
        color: ${theme.form.fields.color};
        resize: none;
    `

    function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        setValue(e.target.value)
    }
    return <label css={containerStyle} {...props}>
        <div css={labelStyle}>{ label }</div>
        {errors && <FieldError errors={errors} />}
        <textarea
            css={inputStyle}
            onChange={handleChange}
            required={required}
            value={value}
            placeholder={placeholder}
        />
    </label>
}

export const DynamicTextarea: FieldComponent<TextareaConfig> = ({
    value,
    setValue,
    config,
    errors
}) => <Textarea
    value={value}
    setValue={setValue}
    required={config.required}
    placeholder={config.placeholder}
    label={config.label}
    errors={errors}
/>