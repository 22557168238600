import { createContext, FC, ReactNode, useContext, useState } from "react";

type NavContextValue = {
    navOpen: boolean
    setNavOpen: (value: boolean) => void
}

const context = createContext<NavContextValue>(undefined!)
export const useNavContext = () => useContext(context)

export const NavContext: FC<{children: ReactNode}> = ({ children }) => {
    const [navOpen, setNavOpen] = useState(false)
    return <context.Provider value={{
        navOpen,
        setNavOpen
    }}>
        { children }
    </context.Provider>
}