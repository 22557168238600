import { FC, useEffect } from "react"
import { Loading } from '../common/loading/Loading'
import { useEventContentContext } from "../common/event-content/EventContentContext"
import { LoadedAgenda } from "./LoadedAgenda"

export const Agenda: FC = () => {

    const { eventContentRequest } = useEventContentContext()

    useEffect(() => {
        eventContentRequest.reload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <Loading
        request={eventContentRequest} 
        render={({agenda}) => 
            <LoadedAgenda 
                sessions={agenda.sessions} 
                dates={agenda.dates}
            />
        }
        reloadMode="show-old-data"
    />

}

