import { PublicEventDetails } from "@marketpartner/backend-api";
import { useFetch } from "@marketpartner/mp-common-react";
import { FC, ReactNode, useEffect } from "react";
import { useBackend } from "../common/hooks/useBackend";
import { useEventLocalStorage } from "../common/hooks/useEventLocalStorage";
import { Loading } from "../common/loading/Loading";
import { eventDetails } from "../eventDetails";

export const CacheBusting: FC<{children: ReactNode}> = ({ children }) => {

    const { clientId, eventId } = eventDetails
    const backend = useBackend()

    const eventRequest = useFetch(
        () => backend.events().getEvent(clientId, eventId),
        [],
        {
            reloadFrequency: 300000
        }
    )
    return <Loading 
        request={eventRequest}
        render={event => <LoadedCacheBustingContext
            children={children}
            event={event}
        />}
        reloadMode="show-old-data"
    />
    
}

type LoadedCacheBustingProps = {
    children: ReactNode
    event: PublicEventDetails
}

const LoadedCacheBustingContext: FC<LoadedCacheBustingProps> = ({ 
    children,
    event
}) => {

    const [cacheBuster, setCacheBuster] = useEventLocalStorage('cacheBuster', 0)
    const newCacheBuster = event.extraFields.PublicPwaCacheBuster

    useEffect(() => {
        if (newCacheBuster) {
            const parsedCacheBuster = parseInt(newCacheBuster)
            if (parsedCacheBuster > cacheBuster) {
                setCacheBuster(parsedCacheBuster)
                window.location.reload()
                return
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newCacheBuster])

    return <>{ children }</>
}