import { useViewport } from "../ViewportContext"
import { DesktopNav } from "./DesktopNav"
import { MobileNav } from "./MobileNav"
import { NavBar } from "./NavBar"
import { useEffect } from "react"
import { useNavContext } from "./NavContext"

export const Navigation = () => {

    const { navOpen, setNavOpen } = useNavContext()
    
    const { isMobile } = useViewport()

    useEffect(() => {
        if (isMobile) {
            setNavOpen(false)
        }
    }, [isMobile, setNavOpen])
    
    return <>
        { !isMobile && <DesktopNav />}
        { (isMobile && navOpen ) && <MobileNav />}
        { isMobile && <NavBar navOpen={navOpen} setNavOpen={setNavOpen} /> }
    </>
}
