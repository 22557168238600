/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Route, Routes } from 'react-router-dom'
import { pages } from './pages'
import { useScrollContext } from '../ScrollContext'
import { Home } from '../../home/Home'
import { Popup } from '../popup/Popup'
import { useTheme } from '../../ThemeSelector'
import { PageRenderer } from './PageRenderer'
import { breakpoint2 } from '../ViewportContext'

export const Page = () => {

    const { containerRef } = useScrollContext()
    const theme = useTheme()

    const pageStyle = css`
        flex: 1 1 auto;
        background-color: ${theme.app.backgroundColor};
        ${theme.app.backgroundImage && css`
            background-image: url(${theme.app.backgroundImage}); 
            background-size: cover;
            background-position: center center;
        `}
        
        padding: 0 10px;
        display: flex;
        justify-content: center;
        overflow: auto;
        position: relative;
        @media screen and (max-width: ${breakpoint2}px) {
            padding: 0;
        }
    `

    return <div css={pageStyle} ref={containerRef}>
        <Popup />
        <Routes>
            <Route path="/" element={<Home />} />
            {pages
                .map(page => <Route
                    key={page.title}
                    element={<PageRenderer page={page} />}
                    path={page.path}
                />)
            }
        </Routes>

    </div>
}

