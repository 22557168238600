/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC, useState } from "react"
import { ListItem } from '../common/ListItem'
import { useTheme } from '../ThemeSelector'
import { SearchContacts } from './SearchContacts'
import { Delegate } from './useFetchDelegates'

type LoadedDelegatesProps = {
    delegates: Delegate[] | null
}

export const LoadedDelegates: FC<LoadedDelegatesProps> = ({
    delegates
}) => {


    const theme = useTheme()

    const containerStyle = css`
        padding-top: 15px;
    `

    const delegateRowStyle = css`
        width: 100%;
    `

    const nameStyle = css`
        color: ${theme.list.item.colorAccent};
    `

    const jobTitleStyle = css`
        color: ${theme.list.item.color};
    `
    const [search, setSearch] = useState('')
    const filteredDelegates = delegates 
        ? delegates
            .sort(sortDelegates)
            .filter(delegate => matchDelegate(delegate, search))
        : []
    return <div css={containerStyle}>
        <SearchContacts
            search={search}
            setSearch={setSearch}
        />
        {filteredDelegates.map(delegate => <ListItem
            key={`${delegate.firstName}-${delegate.lastName}-${delegate.company}`}
        >
            <div css={delegateRowStyle}>
                <div css={nameStyle}>
                    {delegate.firstName} {delegate.lastName}
                </div>
                <div css={jobTitleStyle}>
                    {delegate.company}
                </div>
            </div>
        </ListItem>)}
    </div>
}

type GenericContact = {
    firstName: string | null
    lastName: string | null
    company: string | null
}
export function matchDelegate(delegate: GenericContact, searchString: string): boolean {
    const string = `${delegate.firstName} ${delegate.lastName} ${delegate.company}`
    const slugifiedString = slugify(string)
    return string.includes(searchString) || slugifiedString.includes(slugify(searchString))
}

export function slugify(str: string): string {
    return str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');
}

export function sortDelegates(a: GenericContact, b: GenericContact): 1 | 0 | -1 {
    const aFirstName = a.firstName ?? ''
    const bFirstName = b.firstName ?? ''
    const aLastName = a.lastName ?? ''
    const bLastName = b.lastName ?? ''
    if (aLastName > bLastName) return 1
    if (aLastName < bLastName) return -1
    if (aFirstName > bFirstName) return 1
    if (aFirstName < bFirstName) return -1
    return 0
}