import axios from "axios"
import { eventDetails } from "../eventDetails"
import parse from "html-react-parser" 

export type ParsedString = JSX.Element[] | JSX.Element | string
type Response = {
    id: string
    value: string
    text: ParsedString
}
export type Poll = {
    id: string
    text: ParsedString
    maxResponses: number
    responses: Response[]
    heading: string
    subheading: string
}

type PostResponse = {
    id: string
    pollId: string
}

// const { clientId, eventId } = eventDetails
// const eventToken = `${clientId}${eventId}`
const { pollingToken } = eventDetails

const api = axios.create({
    baseURL: "https://4fxbqto4j5.execute-api.eu-west-2.amazonaws.com/prod/",
    headers: {
        'content-type': 'application/json'
    }
})

export const pollingApi = {
    load: (): Promise<Poll | null> => api
        .get(`polls?current=Y&eventToken=${pollingToken}`)
        .then(response => transformPoll(response?.data?.data?.polls)),
    post: (response: PostResponse, accessToken: string) => api
        .post("/poll-responses", {
            responseToken: response.id,
            pollId: response.pollId,
            userSessionToken: accessToken,
            eventToken: pollingToken
        })
        .then(res => res.data)
}

function transformPoll(polls: any[]): Poll | null {
    if (polls === undefined) return null
    if (polls.length === 0) return null
    const poll = polls[0]
    return {
        id: poll.pollId,
        text: parse(poll.attributes.text),
        maxResponses: poll.attributes.numResponses,
        responses: poll.attributes.choices.map(transformResponse),
        heading: poll.attributes.heading,
        subheading: poll.attributes.subheading
    }
}

function transformResponse(response: any): Response {
    return {
        ...response,
        id: response.value,
        text: parse(response.text)
    }
}