import { FlatEpCompany, NetworkingProfile } from "@marketpartner/backend-api";
import { createContext, FC, ReactNode, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Contact, FlatEpSessionWithLuxonTimes, ResourceWithMedia } from "../event-content/useEventContentFetch";


export type PopupData = {
    type: "session"
    data: FlatEpSessionWithLuxonTimes
} | {
    type: "speaker"
    data: Contact
} | {
    type: "networking-user"
    data: {
        user: NetworkingProfile
        isMessageable: boolean
    }
} | {
    type: "resource"
    data: ResourceWithMedia
} | {
    type: "sponsor"
    data: FlatEpCompany
}

type PopupContextValue = {
    currentPopup: PopupData | undefined
    showPopup: (popupData: PopupData) => void
    goToPreviousPopup: () => void
    clearPopupData: () => void
    showReturnButton: boolean
}

const context = createContext<PopupContextValue>(undefined!)
export const usePopupContext = () => useContext(context)

export const PopupContext: FC<{children: ReactNode}> = ({ children }) => {
    
    const [popupHistory, setPopupHistory] = useState<PopupData[]>([])
    const location = useLocation()

    useEffect(() => {
        setPopupHistory([])
    }, [location.pathname])

    function clearPopupData() {
        setPopupHistory([])
    }

    function showPopup(newPopupData: PopupData) {
        setPopupHistory(prevItems => [
            ...prevItems,
            newPopupData
        ])
    }

    function goToPreviousPopup() {
        setPopupHistory(popupHistory.slice(0, -1))
    }

    const currentPopup = popupHistory.length ? popupHistory[popupHistory.length - 1] : undefined
    const showReturnButton = popupHistory.length > 1

    return <context.Provider value={{
        currentPopup,
        showPopup,
        goToPreviousPopup,
        clearPopupData,
        showReturnButton
    }}>
        { children }
    </context.Provider>
}