import { useState, useEffect, useContext, createContext, FC, ReactNode } from 'react'

type ViewportContextValue = {
    width: number
    height: number
    isDesktop: boolean
    isMobile: boolean
}

export const breakpoint1 = 600
export const breakpoint2 = 400
const viewportContext = createContext<ViewportContextValue>(undefined!);
export const useViewport = () => useContext(viewportContext)

export const ViewportContext:FC<{children: ReactNode}> = ({ children }) => {

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const handleWindowResize = () => {
        if (!document.fullscreenElement) {
            setWidth(window.innerWidth)
            setHeight(window.innerHeight)
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    /* Now we are dealing with a context instead of a Hook, so instead
       of returning the width and height we store the values in the
       value of the Provider */
    return (
        <viewportContext.Provider value={{
            width,
            height,
            isDesktop: width > breakpoint1,
            isMobile: width <= breakpoint1,
        }}>
            {children}
        </viewportContext.Provider>
    );
};
