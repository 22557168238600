/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC } from "react"
import { useTheme } from "../ThemeSelector"
import { Avatar } from "./Avatar"
import { ReactComponent as InfoIcon } from "../agenda/info-icon.svg"
import { ReactComponent as AgendaIcon } from "../agenda/agenda-icon.svg"
import { Contact } from "../common/event-content/useEventContentFetch"
type SpeakerSummaryProps = {
    speaker: Contact
    isClickable: boolean
}
export const SpeakerSummary: FC<SpeakerSummaryProps> = ({
    speaker,
    isClickable
}) => {

    const theme = useTheme()

    const containerStyle = css`
        width: 100%;
        display: flex;
        text-align: left;
    `

    const detailsStyle = css`
        padding-left: 10px;
        width: 100%;
    `

    const topRowStyle = css`
        width: 100%;
        display: flex;
        justify-content: space-between;
        color: ${theme.list.item.color};
        margin-bottom: 10px;
    `

    const nameStyle = css`
        font-weight: 500;
        color: ${theme.list.item.colorAccent};
    `

    const actionsStyle = css`
        align-items: center;
        display: flex;
        flex-shrink: 0;
    `

    const iconStyle = css`
        height: 15px; 
        width: 15px;
        margin-right: 5px;
        transition: fill .1s;
        fill: ${theme.list.item.action.fill};
    `

    const chevronStyle = css`
        border-style: solid;
        border-width: 1px 1px 0 0;
        border-color: ${theme.list.item.chevron.color};
        display: inline-block;
        height: 6px;
        transform: rotate(45deg);
        margin-right: 10px;
        width: 6px;
        transition: border-color .1s;
    `

    const jobTitleStyle = css`
        color: ${theme.list.item.color};
    `

    const companyStyle = css`
        color: ${theme.list.item.color};
    `
    return <div css={containerStyle}>
        <Avatar
            imageUrl={speaker.profile_photo_cropped}
        />
        <div css={detailsStyle}>
            <div css={topRowStyle}>
                <div css={nameStyle}>
                    {getFullName(speaker)}
                </div>
                { isClickable && <div css={actionsStyle} className="actions">
                    { speaker.sessions.length > 0 && <AgendaIcon className="icon" css={iconStyle} />}
                    { speaker.bio && <InfoIcon className="icon" css={iconStyle} />}
                    <div className="chevron" css={chevronStyle} />
                </div> }
            </div>
            {speaker.job_title && <div css={jobTitleStyle}>
                {speaker.job_title}
            </div>}
            {speaker.company && <div css={companyStyle}>
                {speaker.company}
            </div>}
        </div>
    </div>
}

export function getFullName(speaker: Contact) {
    return `${speaker.first_name} ${speaker.last_name}`
}
