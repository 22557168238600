/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC } from "react"
import { ContentCategory } from '../common/event-content/useEventContentFetch'
import { useTheme } from '../ThemeSelector'

type ResourceCategoryTabsProps = {
    categories: ContentCategory[]
    activeCategory: string
    setActiveCategory: (newActiveCategory: string) => void
}

const TabsStyle = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    padding: 15px 0;
    gap: 10px;
`

export const ResourceCategoryTabs: FC<ResourceCategoryTabsProps> = ({
    activeCategory,
    categories,
    setActiveCategory
}) => {
    return <div css={TabsStyle}>
        
        { [{id: '', name: 'All'},...categories].map(category => <CategoryTab 
            key={category.id}
            category={category}
            isActiveCategory={category.id === activeCategory}
            setActiveCategory={setActiveCategory}
        />)}
    </div>
}

type CategoryTabProps = {
    category: ContentCategory
    isActiveCategory: boolean
    setActiveCategory: (newActiveCategory: string) => void
}

const CategoryTab: FC<CategoryTabProps> = ({
    isActiveCategory,
    category,
    setActiveCategory
}) => {
    
    const handleClick = () => {
        setActiveCategory(category.id)
    }

    const theme = useTheme()

    const tabStyle = css`
        flex: 1 1 auto;
        flex-grow: 0;
        padding: 0 5px;
        cursor: pointer;
        color: ${theme.agenda.date.tabs.color};
        ${isActiveCategory && css`
            border-bottom: 2px solid ${theme.agenda.date.tabs.borderColor};
        `}
    `
    return <div 
        onClick={handleClick} 
        css={tabStyle}
    >
        {category.name}
    </div>
}