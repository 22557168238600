/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { NetworkingProfile } from "@marketpartner/backend-api"
import { FC } from "react"
import { ReactComponent as InfoIcon } from "../agenda/info-icon.svg"
import { ReactComponent as MailIcon } from "./mail-icon.svg"
import { useTheme } from "../ThemeSelector"
import { usePopupContext } from '../common/popup/PopupContext'

type NetworkingUserProps = {
    user: NetworkingProfile
    isCurrentUser: boolean    
}
export const NetworkingUser: FC<NetworkingUserProps> = ({
    user,
    isCurrentUser
}) => {

    const theme = useTheme()

    const isMessageable = !isCurrentUser && user.directMessageOptIn
    const isClickable = isMessageable || user.bioHtml
    const { showPopup } = usePopupContext()


    const containerStyle = css`
        width: 100%;
        display: flex;
        text-align: left;
        ${isClickable && css`
            cursor: pointer;
        `}
    `

    const detailsStyle = css`
        padding-left: 10px;
        width: 100%;
    `

    const topRowStyle = css`
        width: 100%;
        display: flex;
        justify-content: space-between;
        color: ${theme.list.item.color};
        margin-bottom: 10px;
    `

    const nameStyle = css`
        font-weight: 500;
        color: ${theme.list.item.colorAccent};
    `

    const actionsStyle = css`
        align-items: center;
        display: flex;
        flex-shrink: 0;
    `

    const iconStyle = css`
        height: 15px; 
        width: 15px;
        margin-right: 5px;
        transition: fill .1s;
        fill: ${theme.list.item.action.fill};
    `

    const chevronStyle = css`
        border-style: solid;
        border-width: 1px 1px 0 0;
        border-color: ${theme.list.item.chevron.color};
        display: inline-block;
        height: 6px;
        transform: rotate(45deg);
        margin-right: 10px;
        width: 6px;
        transition: border-color .1s;
    `

    const jobTitleStyle = css`
        color: ${theme.list.item.color};
    `

    const companyStyle = css`
        color: ${theme.list.item.color};
    `

    function handleClick() {
        if (isClickable) {
            showPopup({
                type: 'networking-user',
                data: {
                    user,
                    isMessageable
                }
            })
        }
    }

    return <div css={containerStyle} onClick={handleClick}>
        <div css={detailsStyle}>
            <div css={topRowStyle}>
                <div css={nameStyle}>
                    {`${user.firstName} ${user.lastName}`}
                </div>
                { isClickable && <div css={actionsStyle} className="actions">
                    { isMessageable && <MailIcon className="icon" css={iconStyle} />}
                    { user.bioHtml && <InfoIcon className="icon" css={iconStyle} />}
                    <div className="chevron" css={chevronStyle} />
                </div> }
            </div>
            {user.jobTitle && <div css={jobTitleStyle}>
                {user.jobTitle}
            </div>}
            {user.company && <div css={companyStyle}>
                {user.company}
            </div>}
        </div>
    </div>
}