/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC } from "react"
type AvatarProps = {
    imageUrl: string
    className?: string
}


export const Avatar: FC<AvatarProps> = ({
    imageUrl,
    ...props
}) => {

    const style = css`
        border-radius: 50%;
        width: 50px;
        height: 50px;
        background-image: url(${imageUrl});
        background-size: cover;
        background-position: center;
        flex-shrink: 0;
    `
    return <div 
        css={style} 
        {...props}
    />
}