/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useUpdate } from "@marketpartner/mp-common-react"
import { FC, useState } from "react"
import { useAuthenticationContext } from "../common/authentication/AuthenticationContext"
import { Button } from "../common/Button"
import { RadioButtons } from "../common/form/fields/RadioButtons"
import { TextField } from '../common/form/fields/TextField'
import { useBackend } from "../common/hooks/useBackend"
import { LoadingSpinner } from '../common/loading/LoadingSpinner'
import { P } from "../common/typography"
import { eventDetails } from "../eventDetails"
import { useTheme } from '../ThemeSelector'
type NetworkingFormProps = {
    onSuccess: () => void
}

const { clientId, eventId } = eventDetails

export const NetworkingForm: FC<NetworkingFormProps> = ({
    onSuccess
}) => {

    const theme = useTheme()

    const containerStyle = css`
        position: relative;
    `

    const { registration } = useAuthenticationContext()
    const backend = useBackend()

    const [error, setError] = useState('')

    const [company, setCompany] = useState(registration.fields.company as string ?? "")
    const [jobTitle, setJobTitle] = useState(registration.fields.job_title as string ?? "")
    const [signup, setSignup] = useState('')

    const formFilled = Boolean(company) && Boolean(jobTitle) && Boolean(signup)


    const submitProfileRequest = useUpdate({
        update: () => {
            setError('')
            return backend.networking().createProfile(clientId, eventId, {
                company: company,
                jobTitle: jobTitle,
                directMessageOptIn: signup === 'Yes',
                bioMd: null,
                avatarBase64: null,
                linkedinUsername: null,
                twitterUsername: null
            })
        },
        onSuccess,
        onError: (error: any) => setError(error.message ?? "An error occurred.")
    })
    return <div css={containerStyle}>

        { submitProfileRequest.isLoading && <LoadingSpinner absolute={true} />}
        { error && <P css={css`color: ${theme.form.message.error.color};`}>{ error }</P>}

        <P css={css`margin-top: 20px;`}>Please confirm your details to sign up to networking.</P>

        <TextField 
            label='Job title'
            placeholder='Enter job title'
            required={true}
            value={jobTitle}
            setValue={setJobTitle}
        />

        <TextField 
            label='Company'
            placeholder='Enter company'
            required={true}
            value={company}
            setValue={setCompany}
        />

        <RadioButtons 
            label="Please indicate whether you would like to opt in to be able to send/receive messages to/from other delegates."
            required={true}
            choices={[
                {
                    label: "Yes",
                    value: "Yes"
                },
                {
                    label: "No",
                    value: "No"
                }
            ]}
            value={signup}
            setValue={setSignup}
            name="signup"
        />

        <Button
            css={css`margin-top: 20px;`}
            disabled={!formFilled || submitProfileRequest.isLoading}
            onClick={submitProfileRequest.trigger}
        >Sign up for networking</Button>

    </div>
}