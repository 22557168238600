/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC } from "react"

type SponsorLogoProps = {
    src: string
    width?: string
    height?: string
    vAlign?: 'left' | 'center' | 'right'
    className?: string
}

export const SponsorLogo: FC<SponsorLogoProps> = ({
    src,
    height,
    width,
    vAlign,
    ...props
}) => {

    const containerStyle = css`
        width: ${width ?? '100%'};
    `

    const innerStyle = css`
        position: relative;
        padding-top: ${height ?? '37%'};
    `

    const imageStyle = css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(${src});
        background-size: contain;
        background-position: center ${vAlign ?? 'left'};
        background-repeat: no-repeat;
    `

    return <div css={containerStyle} {...props}>
        <div css={innerStyle}>
            <div css={imageStyle} />
        </div>
    </div>

}