/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC, useState } from "react"
import { Company, ContentCategory } from '../common/event-content/useEventContentFetch'
import { ResourceCategoryTabs } from '../resources/ResourceCategoryTabs'
import { SponsorRow } from './SponsorRow'
type LoadedSponsorsProps = {
    sponsors: Company[]
    categories: ContentCategory[]
}

const containerStyle = css`
    
`

export const LoadedSponsors: FC<LoadedSponsorsProps> = ({
    sponsors,
    categories
}) => {

    const [activeCategory, setActiveCateory] = useState('')

    return <div css={containerStyle}>
        <ResourceCategoryTabs
            activeCategory={activeCategory}
            setActiveCategory={setActiveCateory}
            categories={categories}
        />
        { sponsors
            .filter(sponsor => sponsor.category === activeCategory || activeCategory === '')
            .map(sponsor => <SponsorRow 
                sponsor={sponsor}
                key={sponsor.entity_token}
            />)
        }
    </div>
}