/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import parse from "html-react-parser"
import { FC } from "react"
import { useEventContentContext } from '../common/event-content/EventContentContext'
import { Contact, FlatEpSessionWithLuxonTimes, getCategories } from '../common/event-content/useEventContentFetch'
import { ListItem } from '../common/ListItem'
import { ListItemHeading } from '../common/ListItemHeading'
import { usePopupContext } from '../common/popup/PopupContext'
import { H3 } from '../common/typography'
import { SpeakerSummary } from '../speakers/SpeakerSummary'
import { useTheme } from '../ThemeSelector'

type SessionPopupProps = {
    session: FlatEpSessionWithLuxonTimes
}

export const SessionPopup: FC<SessionPopupProps> = ({session}) => {
    
    const theme = useTheme()

    const containerStyle = css`
        text-align: center;
    `

    const headingStyle = css`
        text-align: center; 
        color: ${theme.popup.heading.color};
    `

    const timesStyle = css`
        text-align: center;
        margin: 10px 0 40px;
        color: ${theme.popup.color};
    `

    const synopsisStyle = css`
        text-align: left;
        color: ${theme.popup.color};
    `

    const speakersStyle = css`
        padding-top: 20px;
    `

    const { findSpeaker } = useEventContentContext()
    const { showPopup } = usePopupContext()

    const speakers = session.speakers.map(speaker => findSpeaker(speaker.entity_token) as Contact)
    const speakerCategories = getCategories(speakers)

    return <div css={containerStyle}>
        <H3 css={headingStyle}>{ session.name }</H3>
        <div css={timesStyle}>{ session.start.toFormat('HH:mm') } – { session.end.toFormat('HH:mm') }</div>
        <div css={synopsisStyle}>{ parse(session.synopsis) }</div>

        <div css={speakersStyle}>

            { speakerCategories.map(cat => <div
                key={cat.id}
            >
                <ListItemHeading>{ cat.name }</ListItemHeading>
                { speakers
                    .filter(speaker => speaker.category === cat.id)
                    .map(speaker => <ListItem
                        key={speaker.entity_token}
                        onClick={() => showPopup({
                            data: speaker,
                            type: "speaker"
                        })}
                    >
                        <SpeakerSummary speaker={speaker} isClickable={speaker.isClickable} />
                    </ListItem>)
                }
            </div>)}
        </div>
    </div>
}