/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC, ReactNode } from "react"
import { useTheme } from "../ThemeSelector"

type ButtonProps = {
    onClick?: () => void
    className?: string
    disabled?: boolean
    children: ReactNode
}

export const Button: FC<ButtonProps> = ({...props}) => {

    const theme = useTheme()


    const buttonStyle = css`
        display: inline-block;
        font-size: 16px;
        font-weight: 700;
        border-radius: 1px;
        padding: 10px 30px 12px;
        color: ${theme.app.button.primary.color};
        background: ${theme.app.button.primary.backgroundColor};
        text-decoration: none;
        text-align: center;
        outline: none;
        border: none;
        cursor: pointer;
        &:hover {
            background: ${theme.app.button.primary.hover.backgroundColor};
        }
        &:disabled {
            cursor: not-allowed;
            background: grey !important;
        }
    `

    return <button css={buttonStyle} {...props} />

}

