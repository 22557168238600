import { SessionSummary } from './SessionSummary'
import { FC } from 'react'
import { usePopupContext } from '../common/popup/PopupContext'
import { ListItem } from '../common/ListItem'
import { FlatEpSessionWithLuxonTimes } from '../common/event-content/useEventContentFetch'

type Props = {
    session: FlatEpSessionWithLuxonTimes
}

export const SessionRow: FC<Props> = ({ 
    session 
}) => {

    const { showPopup } = usePopupContext()

    function onShowPopup() {
        showPopup({
            type: "session",
            data: session
        })
    }

    return <ListItem
        onClick={session.isClickable ? onShowPopup : undefined}
    >
        <SessionSummary session={session} isClickable={session.isClickable} />
    </ListItem>
}