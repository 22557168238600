/** @jsxImportSource @emotion/react */
import './App.css';
import { Navigation } from './common/navigation/Navigation';
import { ViewportContext } from './common/ViewportContext'
import { ThemeSelector } from './ThemeSelector';
import { HashRouter } from 'react-router-dom';
import { Page } from './common/routing/Page';
import { ScrollContext } from './common/ScrollContext';
import { NavContext } from './common/navigation/NavContext';
import { PopupContext } from './common/popup/PopupContext';
import { UrlParametersContext } from './common/UrlParametersContext';
import { TokenContext } from './common/authentication/TokenContext';
import { ActivitiesContext } from './common/activities/ActivitiesContext';
import { EventContentContext } from './common/event-content/EventContentContext';
import { CacheBusting } from './common/CacheBusting';


function App() {
    return (
        <ThemeSelector>
            <UrlParametersContext>
                <HashRouter>
                    <ViewportContext>
                        <div id="app">
                            <TokenContext>
                                <CacheBusting>
                                    <EventContentContext>
                                        <ActivitiesContext>
                                            <PopupContext>
                                                <NavContext>
                                                    <ScrollContext>
                                                        <Navigation />
                                                        <Page />
                                                    </ScrollContext>
                                                </NavContext>
                                            </PopupContext>
                                        </ActivitiesContext>
                                    </EventContentContext>
                                </CacheBusting>
                            </TokenContext>
                        </div>
                    </ViewportContext>
                </HashRouter>
            </UrlParametersContext>
        </ThemeSelector>
    );
}

export default App;

