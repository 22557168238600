/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { SessionPopup } from '../../agenda/SessionPopup'
import { NetworkingUserPopup } from '../../networking/NetworkingUserPopup'
import { ResourcePopup } from '../../resources/ResourcePopup'
import { SpeakerPopup } from '../../speakers/SpeakerPopup'
import { SponsorPopup } from '../../sponsors/SponsorPopup'
import { useTheme } from '../../ThemeSelector'
import { usePopupContext } from './PopupContext'
import { ReactComponent as BackIcon } from "./back-icon.svg"
import { useEffect } from 'react'

export const Popup = () => {

    const theme = useTheme()

    const containerStyle = css`
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding-bottom: 50px;
        z-index: 1;
        display: flex;
    `

    const backgroundStyle = css`
        background: ${theme.popup.backgroundColor};
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
    `

    const modalStyle = css`
        background: ${theme.popup.backgroundColor};
        position: relative;
        margin: auto auto;
        max-width: 600px;
        width: 100%;
        height: 100%;
        padding: 50px;
        color: ${theme.popup.color};
        overflow-y: auto;
    `
    const closeStyle = css`
        position: absolute;
        top: 20px;
        right: 20px;
        font-weight: 500;
        font-size: 18px;
        color: ${theme.popup.color};
        cursor: pointer;
    `
    
    const backStyle = css`
        position: absolute;
        top: 20px;
        left: 20px;
        cursor: pointer;
        fill: ${theme.popup.color};
        stroke: ${theme.popup.color};
        width: 30px;
        height: 30px;
    `

    const { currentPopup, clearPopupData, goToPreviousPopup, showReturnButton } = usePopupContext()

    useEffect(() => {
        const scrollablePopup = document.getElementById('scrollable-popup')
        if (!scrollablePopup) {
            return
        }
        scrollablePopup.scrollTop = 0
    }, [currentPopup])

    if (currentPopup === undefined) return <></>

    return <div css={containerStyle}>
        <div css={backgroundStyle} onClick={clearPopupData}></div>
        <div css={modalStyle}>
            <div css={closeStyle} onClick={clearPopupData}>&#10005;</div>
            { showReturnButton && <BackIcon css={backStyle} onClick={goToPreviousPopup} /> }
            { currentPopup.type === 'session' && <SessionPopup session={currentPopup.data} /> }
            { currentPopup.type === 'speaker' && <SpeakerPopup speaker={currentPopup.data} /> }
            { currentPopup.type === 'networking-user' && <NetworkingUserPopup user={currentPopup.data.user} isMessageable={currentPopup.data.isMessageable} /> }
            { currentPopup.type === 'resource' && <ResourcePopup resource={currentPopup.data} />}
            { currentPopup.type === 'sponsor' && <SponsorPopup sponsor={currentPopup.data} />}
        </div>
    </div>
}