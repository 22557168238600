/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC, ReactNode, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useViewport } from '../ViewportContext'


export const SectionContent: FC<{children: ReactNode}> = ({ children }) => {

    const { isMobile } = useViewport()

    const contentParentStyle = css`
        flex: 1 1 auto;
        overflow-y: auto;
        overflow-x: hidden;
        overflow: hidden;
        display: flex;
       `
    const contentChildStyle = css`
        flex: 1 1 auto;
        overflow: auto;
        padding: 0px 20px;
        padding-bottom: ${isMobile ? '100px' : '20px'};
    `

    const scrollableDiv = useRef<HTMLDivElement>(null)
    const { pathname } = useLocation()

    useEffect(() => {
        scrollableDiv.current?.scrollTo({top: 0})
    }, [pathname])

    return <div css={contentParentStyle}>
        <div css={contentChildStyle} ref={scrollableDiv}>
            {children}
        </div>
    </div>
}