import { FC } from "react"
import { DownloadInstructions } from './DownloadInstructions'


export const About:FC = () => {

    return <>
        <DownloadInstructions />        

    </>
}