const devPathname = "companion-app-template"

export function getEnvironment() {
    const host = window.location.host
    const pathname = window.location.pathname.replaceAll('/','')
    if (host === "localhost:3001") {
        return "live"
    }
    if (host === "localhost:3000" || pathname === devPathname) {
        return "dev"
    }
    return "live"
}