/** @jsxImportSource @emotion/react */
import { FC, useState } from 'react'
import { Date, FlatEpSessionWithLuxonTimes } from '../common/event-content/useEventContentFetch'
import { AgendaDateHeading } from './AgendaDateHeading'
import { AgendaDateTabs } from './AgendaDateTabs'
import { SessionRow } from './SessionRow'

type LoadedAgendaProps = {
    sessions: FlatEpSessionWithLuxonTimes[]
    dates: Date[]
}

export const LoadedAgenda: FC<LoadedAgendaProps> = ({ sessions, dates }) => {
    
    const [activeDate, setActiveDate] = useState(dates[0]?.datestring ?? '')

    const filteredSessions = sessions
        .filter(session => session.day === activeDate)

    return <>
        <AgendaDateTabs 
            dates={dates} 
            activeDate={activeDate} 
            setActiveDate={setActiveDate} 
        />
        <AgendaDateHeading 
            dates={dates} 
            activeDate={activeDate} 
        />
        { filteredSessions.map(session => <SessionRow
            key={session.entity_token}
            session={session}
        />)}
    </>
}