/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC } from "react"
import { PageRenderer } from '../common/routing/PageRenderer'
import { breakpoint2 } from '../common/ViewportContext'
import { useTheme } from '../ThemeSelector'
import { RegForm } from "./RegistrationForm"

export const Registration:FC = () => {

    const theme = useTheme()

    const pageStyle = css`
        flex: 1 1 auto;
        background-color: ${theme.app.backgroundColor};
        ${theme.app.backgroundImage && css`
            background-image: url(${theme.app.backgroundImage}); 
            background-size: cover;
            background-position: center center;
        `}
        
        padding: 0 10px;
        display: flex;
        justify-content: center;
        overflow: auto;
        position: relative;
    @media screen and (max-width: ${breakpoint2}px) {
            padding: 0;
        }
    `
    return <div css={pageStyle}>

        <PageRenderer
            page={{
                type: "single-page",
                title: "Register for access",
                element: <RegForm />,
                path: ""
            }}
        />
    </div>
}

