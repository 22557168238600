import { createContext, FC, ReactNode, useContext, useEffect, useState} from "react";
import { useEventLocalStorage } from "../hooks/useEventLocalStorage";
import { LoadingSpinner } from "../loading/LoadingSpinner";
import { useUrlParametersContext } from "../UrlParametersContext";
import { Registration } from "../../registration/Registration";
import { AuthenticationContext } from "./AuthenticationContext";

type TokenContextValue = {
    mpt: string | undefined
    setMpt: (mpt: string) => void
    clearMpt: () => void
}
const context = createContext<TokenContextValue>(undefined!)

export const useTokenContext = () => useContext(context)

export const mptName = 'mpt'

export const TokenContext: FC<{children: ReactNode}> = ({ children }) => {

    const { urlParameters } = useUrlParametersContext()
    const [lsMpt, setLsMpt, clearLsMpt] = useEventLocalStorage<string | undefined>(mptName, undefined)
    const [contextLoaded, setContextLoaded] = useState(false)


    useEffect(() => {
        if (urlParameters.mpt) {
            setLsMpt(urlParameters.mpt)
        }
        setContextLoaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    return <context.Provider
        value={{
            mpt: lsMpt,
            setMpt: setLsMpt,
            clearMpt: clearLsMpt
        }}
    >
        { contextLoaded
            ? <>{ lsMpt 
                ? <AuthenticationContext>
                    { children }
                </AuthenticationContext>
                : <Registration />
            }</>
            : <LoadingSpinner />
        }
        
    </context.Provider>
}