import { FC } from "react"
import { P } from "../common/typography"
import { PollHeadings } from "./PollHeading"
import { Poll } from "./pollingApi"
type HoldingPollProps = {
    poll: Poll
}
export const HoldingPoll: FC<HoldingPollProps> = ({
    poll
}) => {
    return <div>
        <PollHeadings
            heading={poll.heading}
            subheading={poll.subheading}
        />
        <P>{ poll.text }</P>
    </div>
}