import { useEffect } from "react"
import { Loading } from "../common/loading/Loading"
import { useEventContentContext } from "../common/event-content/EventContentContext"
import { LoadedSpeakers } from "./LoadedSpeakers"


export const Speakers = () => {

    const { eventContentRequest } = useEventContentContext()

    useEffect(() => {
        eventContentRequest.reload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <Loading
        request={eventContentRequest}
        render={({speakersData}) => <LoadedSpeakers 
            categories={speakersData.categories}
            speakers={speakersData.speakers}
        />}
        reloadMode="show-old-data"
    />
}