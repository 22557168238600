/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC } from "react"
import { P } from '../common/typography'
import { useTheme } from '../ThemeSelector'
import { ReactComponent as ShareIcon } from './share-icon.svg'
import { ReactComponent as AddIcon } from './add-icon.svg'
import { ReactComponent as MoreIcon } from './more-android-icon.svg'
import { ReactComponent as AddAndroidIcon } from './add-android-icon.svg'

export const DownloadInstructions:FC = () => {

    const theme = useTheme()

    const highlightStyle = css`
        color: ${theme.app.text.colorActive};
    `

    const iconStyle = css`
        width: 15px;
        height: 15px;
        align-self: flex-end;
        fill: ${theme.app.text.color};
    `
    
    return <>
        <P css={css`margin-top: 30px;`}>This is a responsive web-app which means no app stores are required.</P>
        <P>To save this app to your homescreen please select ‘Add to Home Screen’ by following these instructions:</P>
        <P>
            <span css={highlightStyle}>iPhone using Safari?</span><br />
            Select <ShareIcon css={iconStyle} /> then swipe up to find 'Add to home screen <AddIcon css={iconStyle} /> '.
        </P>
        <P css={css``}>
            <span css={highlightStyle}>On Android?</span><br />
            Open the menu by clicking <MoreIcon css={iconStyle} /> then choose 'Install app <AddAndroidIcon css={iconStyle} />'.
        </P>
    </>
}

