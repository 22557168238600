/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { EmailFieldConfig } from '@marketpartner/backend-api'
import { FieldComponent } from '@marketpartner/mp-common-react'
import { FC } from "react"
import { useTheme } from '../../../ThemeSelector'
import { FieldError } from '../FieldError'

type EmailFieldProps = {
    value: string
    required: boolean
    placeholder: string
    setValue: (value: string) => void
    label: string
    errors?: string[]
}
const containerStyle = css`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    font-size: 16px;
    margin-bottom: 20px;
`

export const EmailField: FC<EmailFieldProps> = ({
    value,
    required,
    placeholder,
    setValue,
    label,
    errors
}) => {
    const theme = useTheme()
    const labelStyle = css`
        color: ${theme.form.fields.color};
        font-weight: 500;
        padding: 0 0 10px;
    `
    const inputStyle = css`
        border: 1px solid ${theme.form.fields.borderColor};
        border-radius: 0;
        background: ${theme.form.fields.backgroundColor};
        outline: none;
        width: 100%;
        padding: 10px;
        font-size: 15px;
        color: ${theme.form.fields.color};
        &:-webkit-autofill {
            box-shadow: 0 0 0px 1000px ${theme.form.fields.backgroundColor} inset;
            -webkit-box-shadow: 0 0 0px 1000px ${theme.form.fields.backgroundColor} inset;
        }
    `
    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setValue(e.target.value)
    }
    return <label css={containerStyle}>
        <div css={labelStyle}>{ label }</div>
        {errors && <FieldError errors={errors} />}
        <input 
            css={inputStyle}
            onChange={handleChange}
            required={required}
            value={value}
            type="email"
            placeholder={placeholder}
        />
    </label>
}

export const DynamicEmailField: FieldComponent<EmailFieldConfig> = ({
    value,
    setValue,
    config,
    errors
}) => <EmailField 
    value={value}
    setValue={setValue}
    required={config.required}
    placeholder={config.placeholder}
    label={config.label}
    errors={errors}
/>