import { FC } from "react"
import { NavItem } from "./NavItem"
import { navLinks } from "./navLinks"


export const NavItems: FC = () => {
    const menuItems = [navLinks.about, navLinks.agenda, navLinks.speakers, navLinks.questions, navLinks.feedback, navLinks.pollingWordcloud, navLinks.networking, navLinks.delegates, navLinks.resources]
    return <>
        {
            menuItems
                .map((navLink) => <NavItem 
                    key={navLink.to}
                    navLink={navLink}
                />)
        }
    </>
}