import { ReactNode } from "react"
import { ReactComponent as AgendaIcon } from "../../agenda/agenda-icon.svg"
import { ReactComponent as SpeakersIcon } from "../../speakers/speakers-icon.svg"
import { ReactComponent as NetworkingIcon } from "../../networking/networking-icon.svg"
import { ReactComponent as FeedbackIcon } from "../../feedback/feedback-icon.svg"
import { ReactComponent as QuestionsIcon } from "../../questions/questions-icon.svg"
import { ReactComponent as AboutIcon } from "../../about/about-icon.svg"
import { ReactComponent as PollingIcon } from "../../polling/polling-icon.svg"
import { ReactComponent as SponsorsIcon } from "../../sponsors/sponsors-icon.svg"
import { ReactComponent as ResourcesIcon } from "../../resources/resources-icon.svg"
import { ReactComponent as CaptionsIcon } from "../../captions/captions-icon.svg"
import { ReactComponent as DelegatesIcon } from "../../delegates/delegates-icon.svg"

export type NavLink = {
    icon: ReactNode
    text: string
    to: string
    scrollTo?: string
    type: 'internal' | 'external'
}

const navLinkLabels = ['about', 'agenda', 'speakers', 'agendaSpeakers', 'delegates', 'questions', 'feedback', 'questionsFeedback', 'pollingWordcloud', 'polling', 'networking', 'sponsors', 'resources', 'captions'] as const
type NavLinksMap = Record<typeof navLinkLabels[number], NavLink>

export const navLinks: NavLinksMap = {
    about: {
        icon: <AboutIcon />,
        text: 'Need to know',
        to: '/about',
        type: 'internal'
    },
    agenda: {
        icon: <AgendaIcon />,
        text: 'Event schedule',
        to: '/agenda-speakers/agenda',
        type: 'internal'
    },
    speakers: {
        icon: <SpeakersIcon />,
        text: 'Speakers',
        to: '/agenda-speakers/speakers',
        type: 'internal'
    },
    agendaSpeakers: {
        icon: <AgendaIcon />,
        text: 'Agenda & Speakers',
        to: '/agenda-speakers',
        type: 'internal'
    },
    feedback: {
        icon: <FeedbackIcon />,
        text: "Feedback & comments",
        to: "/questions-feedback/feedback",
        type: "internal"
    },
    questions: {
        icon: <QuestionsIcon />,
        text: 'Questions',
        to: '/questions-feedback/questions',
        type: 'internal'
    },
    questionsFeedback: {
        icon: <QuestionsIcon />,
        text: "Questions & feedback",
        to: "/questions-feedback",
        type: "internal"
    },
    polling: {
        icon: <PollingIcon />,
        text: 'Live polling',
        to: '/polling',
        type: 'internal'
    },
    pollingWordcloud: {
        icon: <PollingIcon />,
        text: 'Live polling',
        to: '/polling',
        type: 'internal'
    },
    networking: {
        icon: <NetworkingIcon />,
        text: 'Networking',
        to: '/networking',
        type: 'internal'
    },
    delegates: {
        icon: <DelegatesIcon />,
        text: "Delegates",
        to: "/delegates",
        type: 'internal'
    },
    sponsors: {
        icon: <SponsorsIcon />,
        text: 'Sponsors',
        to: '/sponsors',
        type: 'internal'
    },
    resources: {
        icon: <ResourcesIcon />,
        text: 'Resources',
        to: '/resources',
        type: 'internal'
    },
    captions: {
        icon: <CaptionsIcon />,
        text: "Closed captions",
        to: "/captions",
        type: "internal"
    }
}
