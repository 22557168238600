import { FetchRequest } from "@marketpartner/mp-common-react"
import { LoadingError } from "./LoadingError"
import { LoadingSpinner } from "./LoadingSpinner"

type LoadingProps<Data> = {
    request: FetchRequest<Data>
    render: (data: Data) => JSX.Element
    reloadMode?: string
    onRetry?: () => void
}

export const Loading = <Data,>({
    request, 
    render, 
    reloadMode = 'display-spinner',
    onRetry

}: LoadingProps<Data>) => {
    if (request.isLoading && (reloadMode === 'display-spinner' || (!request.data && request.data !== null))) {
        return <LoadingSpinner />
    }
    if (request.data) {
        return render(request.data)
    }
    if (request.data === null) {
        return render(request.data)
    }
    return <LoadingError onRetry={onRetry} error={request.error} />
}