/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC, ReactNode } from "react"
import { useTheme } from '../ThemeSelector'

type ListItemHeadingProps = {
    children: ReactNode
    className?: string
}

export const ListItemHeading: FC<ListItemHeadingProps> = ({
    children,
    ...props
}) => {

    const theme = useTheme()

    const style = css`
        border-bottom: 1px solid ${theme.list.heading.color};
        color: ${theme.list.heading.color};
        font-size: 16px;
        font-weight: 500;
        padding: 5px 0;
        text-align: left;
    `

    return <div css={style} {...props}>{ children }</div>
}