/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC, useState } from "react"
import { ContentCategory, ResourceWithMedia } from '../common/event-content/useEventContentFetch'
import { ResourceCategoryTabs } from './ResourceCategoryTabs'
import { ResourceRow } from './ResourceRow'

type LoadedResourcesProps = {
    resources: ResourceWithMedia[]
    categories: ContentCategory[]
}

const containerStyle = css`
`

export const LoadedResources: FC<LoadedResourcesProps> = ({
    resources,
    categories
}) => {

    const [activeCategory, setActiveCategory] = useState('')

    return <div css={containerStyle}>
        <ResourceCategoryTabs 
            activeCategory={activeCategory}
            categories={categories}
            setActiveCategory={setActiveCategory}
        />

        { resources
            .filter(resource => resource.category === activeCategory || activeCategory === '')
            .map(resource => <ResourceRow
                key={resource.entity_token}
                resource={resource}
            />)
        }
    </div>
}