import { FetchRequest } from "@marketpartner/mp-common-react";
import { createContext, FC, ReactNode, useContext } from "react";
import { Contact, EventContent, FlatEpSessionWithLuxonTimes, useEventContentFetch } from "./useEventContentFetch";

type EventContentContextValue = {
    eventContentRequest: FetchRequest<EventContent>
    findSpeaker: (id: string) => Contact | undefined
    findSession: (id: string) => FlatEpSessionWithLuxonTimes | undefined
}

const context = createContext<EventContentContextValue>(undefined!)
export const useEventContentContext = () => useContext(context)

export const EventContentContext: FC<{children: ReactNode}> = ({ children }) => {

    const eventContentRequest = useEventContentFetch()

    const speakers = eventContentRequest.data?.speakersData.speakers
    const sessions = eventContentRequest.data?.agenda.sessions

    function findSpeaker(id: string): Contact | undefined {
        return speakers?.find(speaker => speaker.entity_token === id)
    }
    
    function findSession(id: string): FlatEpSessionWithLuxonTimes | undefined {
        return sessions?.find(session => session.entity_token === id)
    }

    return <context.Provider value={{
        eventContentRequest,
        findSpeaker,
        findSession
    }}>
        { children }
    </context.Provider>
    
}
