/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC, ReactNode } from "react"
import { useTheme } from "../ThemeSelector"
import { breakpoint1 } from "./ViewportContext"

type TypographyProps = {
    children: ReactNode
    className?: string
}
export const H2: FC<TypographyProps> = ({children, ...props}) => {

    const style = css`
        font-size: 18px;
        font-weight: 500;
        margin: 0 auto 15px;
        width: 100%;
        @media (max-width: ${breakpoint1}px) {
            font-size: 16px;
        }
    `

    return <h2 
        css={style}
        {...props}
    >{children}</h2>

}

export const H3: FC<TypographyProps> = ({children, ...props}) => {

    const theme = useTheme()

    const style = css`
        font-size: 18px;
        font-weight: 400;
        color: ${theme.app.heading.heading3.color};
        margin: 0 auto;
        width: 100%;
    `

    return <h3
        css={style}
        {...props}
    >{children}</h3>

}

export const H4: FC<TypographyProps> = ({children, ...props}) => {

    const style = css`
        font-size: 16px;
        font-weight: 400;
        color: #000;
        margin: 0 auto;
        width: 100%;
    `

    return <h4
        css={style}
        {...props}
    >{children}</h4>

}

export const H5: FC<TypographyProps> = ({children, ...props}) => {

    const style = css`
        font-size: 14px;
        font-weight: 400;
        color: #808080;
        margin: 0 auto;
        width: 100%;
    `

    return <p
        css={style}
        {...props}
    >{children}</p>

}

export const P: FC<TypographyProps> = ({children, ...props}) => {

    const theme = useTheme()

    const style = css`
        text-align: left;
        color: ${theme.app.text.color};
    `

return <p
    css={style}
    {...props}
>{children}</p>

}
