/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { CheckboxesConfig } from "@marketpartner/backend-api"
import { FieldComponent } from "@marketpartner/mp-common-react"
import { FC  } from "react"
import { ParsedString } from "../../../polling/pollingApi"
import { useTheme } from "../../../ThemeSelector"
import { FieldError } from "../FieldError"

type CheckboxesProps = {
    label: ParsedString
    required: boolean
    value: string[]
    setValue: (newValueBuilder: (prevItems: string[]) => string[] ) => void
    choices: {
        label: ParsedString
        value: string
    }[]
    className?: string
    errors?: string[]
    disabled?: boolean
    maxResponses: number
}

export const Checkboxes: FC<CheckboxesProps> = ({
    label, 
    value, 
    setValue, 
    required, 
    choices,
    errors,
    disabled,
    maxResponses,
    ...props
}) => {

    const theme = useTheme()

    const containerStyle = css`
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        font-size: 16px;
    `

    const labelStyle = css`
        margin-bottom: 10px;
        font-weight: 500;
        color: ${theme.form.fields.color};
    `

    const choicesStyle = css`
        display: flex;
        text-align: left;
        width: 100%;
        flex-wrap: wrap;
    `

    const toggleChecked = (choiceValue: string) => {
        if (disabled) return
        if (value.includes(choiceValue)) {
            setValue(prevItems => prevItems.filter((item) => item !== choiceValue))
        } else {
            setValue(prevItems => [...prevItems, choiceValue])
        }
    }

    return <div css={containerStyle} {...props}>
        <div css={labelStyle}>{label}</div>
        {errors && <FieldError errors={errors} />}
        <div css={choicesStyle}>
            {choices.map(choice => <Checkbox
                key={choice.value}
                maxResponsesReached={value.length >= maxResponses}
                isChecked={value.includes(choice.value)} 
                label={choice.label}
                toggleChecked={() => toggleChecked(choice.value)}
                disabled={disabled}
            />)}
        </div>
    </div>

}

type CheckboxProps = {
    isChecked: boolean
    maxResponsesReached: boolean
    toggleChecked: () => void
    label: ParsedString
    disabled?: boolean
}

const Checkbox: FC<CheckboxProps> = ({
    isChecked,
    maxResponsesReached,
    toggleChecked,
    label,
    disabled
}) => {

    const theme = useTheme()

    const labelStyle = css`
        display: flex;
        align-items: center;
        border: 1px solid ${theme.form.fields.borderColor};
        border-radius: 10px;
        color: ${theme.form.fields.color};
        padding: 10px 15px;
        margin: 0 8px 8px 0;
        ${!disabled && css`
            cursor: pointer;
        `}
        position: relative;
        overflow: hidden;
    `

    const inputStyle = css`
        margin: 0;
        position: absolute;
        left: -1000px;
    `

    return <label
        css={css`
            ${labelStyle}
            ${isChecked && css`border-color: ${theme.form.fields.borderColorActive}; font-weight: 700;`}
            ${(maxResponsesReached && !isChecked) && css`border-color: ${theme.form.fields.borderColorDisabled}; color: ${theme.form.fields.colorDisabled};`}
        `}
    >
        <input css={inputStyle}
            type="checkbox"
            checked={isChecked}
            onChange={toggleChecked}
        />
        <div>{label}</div>
    </label>
}

export const DynamicCheckboxes: FieldComponent<CheckboxesConfig> = ({
    config, 
    value, 
    setValue,
    errors
}) => {
    return <Checkboxes
        label={config.label} 
        value={value} 
        setValue={setValue} 
        required={config.required} 
        choices={config.choices} 
        errors={errors}
        maxResponses={1}
    />
}