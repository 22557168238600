/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { ThemeContext, ThemeProvider } from '@emotion/react'
import { FC, ReactNode, useContext, useState } from 'react';
import { getEnvironment } from './common/utility-functions/getEnvironment';
import { Theme, ThemeName, themeNames, themes } from './themes';

export const useTheme = (): Theme => useContext(ThemeContext) as Theme
export const ThemeSelector: FC<{children: ReactNode}> = ({ children }) => {

    const [themeName, setThemeName] = useState<ThemeName>('dark')

    const theme = themes[themeName]

    const isDev = getEnvironment() === 'dev'

    const themePickerStyle = css`
        position: fixed;
        top: 0;
        right: 0;
        z-index: 1000;
    `

    return <ThemeProvider theme={theme}>
        { isDev && <div css={themePickerStyle}>
            { themeNames.map(name => <button
                key={name}
                onClick={() => setThemeName(name)}
            >
                { name }
            </button>)}
        </div>}
        { children }
    </ThemeProvider>
}