import landing from "./landing.png"

export type Theme = {
    agenda: {
        date: {
            heading: {
                color: string
                borderColor: string
            }
            tabs: {
                color: string
                borderColor: string
            }
        }
    }
    app: {
        button: {
            primary: {
                backgroundColor: string
                color: string
                hover: {
                    backgroundColor: string
                    color: string
                }
            }
            standard: {
                backgroundColor: string
                color: string
                hover: {
                    backgroundColor: string
                    color: string
                }
            }
        }
        canvas: {
            backgroundColor: string
        }
        heading: {
            heading3: {
                color: string
            }
        }
        link: {
            color: string
        }
        spinner: {
            color: string
        }
        text: {
            color: string
            colorLight: string
            colorActive: string
        }
        backgroundColor: string
        backgroundImage: string | undefined
    }
    delegates: {
        search: {
            borderColor: string
        }
    }
    form: {
        fields: {
            backgroundColor: string
            borderColor: string
            borderColorActive: string
            borderColorDisabled: string
            color: string
            colorActive: string
            colorDisabled: string
        }
        message: {
            error: {
                color: string
            }
            success: {
                color: string
            }
        }
    }
    list: {
        heading: {
            color: string
        }
        item: {
            action: {
                fill: string
            }
            chevron: {
                color: string
            }
            borderColor: string
            boxShadowColor: string
            color: string
            colorAccent: string
        }
    }
    nav: {
        dock: {
            icon: {
                fill: string
                fillActive: string
            }
            backgroundColor: string
        }
        menu: {
            desktop: {
                backgroundColor: string
            }
            mobile: {
                backgroundColor: string
            }
            color: string
            colorActive: string
            icon: {
                fill: string
            }
        }
    }
    polling: {
        color: string
    }
    popup: {
        heading: {
            color: string
        }
        backgroundColor: string
        color: string
    }
    tab: {
        borderColorActive: string
        color: string
    }
}

const palette = {
    white: {
        main: '#fafafa',
        light: '#fffff0',
        dark: '#f0f0f0'
    },
    black: {
        main: '#0a0a0a',
        light: '#121212',
        dark: '#020202'
    },
    primary: {
        main: '#e01719',
        light: '#f03729',
        dark: '#d00709'
    },
    grey: {
        main: '#818181',
        light: '#d0d0d0',
        dark: '#313131eb'
    },
    red: {
        main: '#aa0000'
    },
    transparent: '#00000000'
}

export const baseTheme: Theme = {
    app: {
        text: {
            color: palette.black.main,
            colorLight: palette.grey.main,
            colorActive: palette.primary.main
        },
        link: {
            color: palette.primary.main
        },
        canvas: {
            backgroundColor: palette.white.main
        },
        heading: {
            heading3: {
                color: palette.black.main
            }
        },
        spinner: {
            color: palette.primary.main
        },
        button: {
            standard: {
                backgroundColor: palette.grey.main,
                color: palette.white.main,
                hover: {
                    backgroundColor: palette.grey.light,
                    color: palette.white.main
                }
            },
            primary: {
                backgroundColor: palette.primary.main,
                color: palette.white.light,
                hover: {
                    backgroundColor: palette.primary.light,
                    color: palette.white.light
                }
            },
        },
        backgroundColor: palette.black.main,
        backgroundImage: landing
    },
    nav: {
        menu: {
            desktop: {
                backgroundColor:  palette.black.main
            },
            mobile: {
                backgroundColor: palette.black.light
            },
            color: palette.white.main,
            colorActive: palette.primary.main,
            icon: {
                fill: palette.white.main
            }
        },
        dock: {
            icon: {
                fill: palette.white.main,
                fillActive: palette.primary.main
            },
            backgroundColor: palette.black.main
        },
    },
    polling: {
        color: palette.black.main
    },
    popup: {
        heading: {
            color: palette.primary.main
        },
        backgroundColor: palette.white.main,
        color: palette.black.main
    },
    tab: {
        color: palette.black.main,
        borderColorActive: palette.primary.main
    },
    agenda: {
        date: {
            heading: {
                color: palette.black.main,
                borderColor: palette.black.main
            },
            tabs: {
                color: palette.black.main,
                borderColor: palette.primary.main
            }
        },
    },
    list: {
        heading: {
            color: palette.black.main
        },
        item: {
            borderColor: palette.grey.dark,
            action: {
                fill: palette.primary.main
            },
            chevron: {
                color: palette.primary.main
            },
            boxShadowColor: palette.primary.light,
            color: palette.grey.main,
            colorAccent: palette.primary.main
        },
    },
    delegates: {
        search: {
            borderColor: palette.primary.main
        }
    },
    form: {
        message: {
            error: {
                color: palette.red.main
            },
            success: {
                color: palette.primary.light
            }
        },
        fields: {
            backgroundColor: palette.white.main,
            borderColor: palette.black.main,
            borderColorActive: palette.primary.main,
            borderColorDisabled: palette.grey.main,
            color: palette.black.main,
            colorActive: palette.primary.main,
            colorDisabled: palette.grey.main
        }
    }
}

export const themeNames = ['dark', 'contrast'] as const
export type ThemeName = typeof themeNames[number]
export type Themes = Record<ThemeName, Theme>

export const themes: Themes = {
    contrast: {
        ...baseTheme
    },
    dark: {
        ...baseTheme,
        agenda: {
            ...baseTheme.agenda,
            date: {
                heading: {
                    borderColor: palette.grey.light,
                    color: palette.grey.light
                },
                tabs: {
                    ...baseTheme.agenda.date.tabs,
                    color: palette.grey.light
                }
            }
        },
        app: {
            ...baseTheme.app,
            canvas: {
                backgroundColor: palette.grey.dark
            },
            text: {
                ...baseTheme.app.text,
                color: palette.white.main
            },
            backgroundColor: palette.black.light
        },
        list: {
            heading: {
                color: palette.white.main
            },
            item: {
                ...baseTheme.list.item,
                borderColor: palette.grey.light,
                color: palette.grey.light
            }
        },
        form: {
            ...baseTheme.form,
            fields: {
                ...baseTheme.form.fields,
                backgroundColor: palette.transparent,
                color: palette.white.main,
                borderColor: palette.grey.light,
                borderColorDisabled: palette.grey.main,
                colorDisabled: palette.grey.main
            }
        },
        nav: {
            ...baseTheme.nav,
            menu: {
                ...baseTheme.nav.menu,
                desktop: {
                    backgroundColor: palette.black.dark
                }
            }
        },
        polling: {
            color: palette.grey.main
        },
        popup: {
            ...baseTheme.popup,
            backgroundColor: palette.grey.dark,
            color: palette.grey.light
        },
        tab: {
            ...baseTheme.tab,
            color: palette.white.main
        }
    }
}

