/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { H2 } from '../typography'
import { FC } from "react"
import { breakpoint1 } from '../ViewportContext'

type PageTitleProps = {
    title: string
}

const titleStyle = css`
    text-align: center;
    color: #ffffff;
    @media screen and (min-width: ${breakpoint1}px) {
        min-height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
    }
`

export const PageTitle: FC<PageTitleProps> = ({ title }) => {

    return <H2 css={titleStyle}>{ title }</H2>
}