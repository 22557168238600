/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { SelectConfig } from "@marketpartner/backend-api"
import { FieldComponent } from "@marketpartner/mp-common-react"
import { FC } from "react"
import { useTheme } from "../../../ThemeSelector"
import { FieldError } from "../FieldError"

export type Choice = {
    value: string
    label: string
    disabled: boolean
}

type SelectProps = {
    label: string
    required: boolean
    choices: Choice[]
    value: string
    setValue: (value: string) => void
    errors?: string[]
    className?: string
}

export const Select: FC<SelectProps> = ({
    label,
    required,
    choices,
    value,
    setValue,
    errors,
    ...props
}) => {

    const theme = useTheme()

    const containerStyle = css`
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        text-align: left;
        width: 100%;
        font-size: 16px;
        margin-bottom: 20px;
    `

    const labelStyle = css`
        margin-bottom: 10px;
        font-weight: 500;
        color: ${theme.form.fields.color};
    `

    const inputStyle = css`
        height: 40px;
        border: 1px solid ${theme.form.fields.borderColor};
        border-radius: 2px;
        padding: 10px;
        font-size: 15px;
        outline: none;
        color: ${theme.form.fields.color};
        background-color: ${theme.form.fields.backgroundColor};
        background-image:
            linear-gradient(45deg, transparent 50%, ${theme.form.fields.borderColor} 50%),
            linear-gradient(135deg, ${theme.form.fields.borderColor} 50%, transparent 50%),
            linear-gradient(to right, ${theme.form.fields.borderColor}, ${theme.form.fields.borderColor});
        background-position:
            calc(100% - 18px) calc(1em + 3px),
            calc(100% - 13px) calc(1em + 3px),
            calc(100% - 2.5em) 8px;
        background-size:
            5px 5px,
            5px 5px,
            1px 1.5em;
        background-repeat: no-repeat;
        -moz-appearance: none; 
        -moz-appearance: none; 
        -webkit-appearance: none; 
        appearance: none;
        
    `

    return <label css={containerStyle} {...props}>
        <div css={labelStyle}>{label}</div>
        {errors && <FieldError errors={errors} />}
        <select css={inputStyle}
            required={required}
            value={value}
            onChange={(e) => setValue(e.target.value)}
        >
            <option value="" disabled={true}>Select one</option>
            {choices.sort(sortByLabel).map((choice) => (
                <option key={choice.value}
                    value={choice.value}
                    disabled={choice.disabled}
                >
                    {choice.label}
                </option>
            ))}
        </select>
    </label>
}

export const DynamicSelect: FieldComponent<SelectConfig> = ({
    config,
    value,
    setValue,
    errors
}) => <Select
    label={config.label}
    value={value}
    setValue={setValue}
    required={config.required}
    choices={config.choices}
    errors={errors}
/>

function sortByLabel(choiceA: Choice, choiceB: Choice): 1 | 0 | -1 {
    if (choiceA.label === 'Other') {
        return 1
    }
    if (choiceB.label === 'Other') {
        return -1
    }
    if (choiceA.label > choiceB.label) {
        return 1
    }
    if (choiceA.label < choiceB.label) {
        return -1
    }
    return 0
    
}