import axios from "axios"

export const emailResource = async (mpt: string, resourceEntityToken: string, clientId: string, eventId: string) => {
    return axios.post('https://mw.marketpartner.com/resource-mailer/', {
        userToken: mpt,
        resourceEntityToken,
        clientId,
        eventId
    }).then(res => res.data)
}
