import { RegistrationForm, RegistrationResponse } from "@marketpartner/backend-api"
import { DynamicFormState } from "@marketpartner/mp-common-react"
import { FC } from "react"
import { DownloadInstructions } from "../about/DownloadInstructions"
import { useTokenContext } from '../common/authentication/TokenContext'
import { DynamicForm } from "../common/form/DynamicForm"
import { useBackend } from "../common/hooks/useBackend"
import { eventDetails } from "../eventDetails"

type LoadedRegistrationProps = {
    form: RegistrationForm
}

const { clientId, eventId } = eventDetails
export const LoadedRegistration: FC<LoadedRegistrationProps> = ({
    form
}) => {

    

    const { setMpt } = useTokenContext()
    
    const backend = useBackend()
    const onSubmit = async (formState: DynamicFormState) => {
        const regResponse = await backend.registration().registerForEvent(clientId, eventId, {
            categoryId: form.registrationCategoryId,
            fields: formState.values
        })
        if (!('accessToken' in regResponse)) {
            const error = new Error("Form configuraiton error")
            throw error
        }
        return regResponse
    }

    const onSuccess = (response: RegistrationResponse) => {
        setMpt(response.accessToken as string)
    }

    return <>
        <DownloadInstructions />
        <DynamicForm
            form={form}
            onSubmit={onSubmit}
            onSuccess={onSuccess}

        />
    </>
}