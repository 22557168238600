/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC } from "react"
import { Date } from '../common/event-content/useEventContentFetch'
import { ListItemHeading } from '../common/ListItemHeading'

type AgendaDateHeadingProps = {
    activeDate: string
    dates: Date[]
    className?: string
}

export const AgendaDateHeading: FC<AgendaDateHeadingProps> = ({
    activeDate, 
    dates,
    ...props
}) => {


    const dateStyle = css`
    `

    const filteredDate = dates.find(date => date.datestring === activeDate)

    if (!filteredDate) return <></>

    return <ListItemHeading {...props} css={dateStyle}>{filteredDate.datetime.toFormat("EEEE, LLLL dd")}</ListItemHeading>
    
}