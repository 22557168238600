/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC } from "react"
import Vimeo from '@u-wave/react-vimeo'

type VimeoPlayerProps = {
    source: string
    className?: string
}

const containerStyle = css`
    width: 100%;
    padding-top: 56.25%;
    position: relative;
`

const innerStyle = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const playerStyle = css`
    width: 100%;
    height: 100%;

    & iframe {
        width: 100%;
        height: 100%;
    }
`

export const VimeoPlayer: FC<VimeoPlayerProps> = ({
    source,
    ...props
}) => {
    return <div css={containerStyle} {...props}>
        <div css={innerStyle}>
            <Vimeo
                video={source}
                autoplay
                css={playerStyle}
                width="100%"
                height="100%"
            // onPlay={onStartWatchingOnDemand}
            // onPause={onStopWatchingOnDemand}
            // onEnd={onStopWatchingOnDemand}
            // onError={onStopWatchingOnDemand}
            />
        </div>
    </div>
}