/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { LinkElement } from '../LinkElement'
import { NavIcon } from './NavIcon'
import { FC } from "react"
import { useTheme } from '../../ThemeSelector'
import { NavLink } from './navLinks'

type NavItemProps = {
    navLink: NavLink
}

export const NavItem: FC<NavItemProps> = ({ 
    navLink
}) => {

    const theme = useTheme()

    const itemStyle = css`
        display: flex;
        margin-bottom: 25px;
        text-decoration: none;
        color: ${theme.nav.menu.color};
        &.is_selected {
            color: ${theme.nav.menu.colorActive}
        }
    `

    const iconStyle = css`
        fill: ${theme.nav.menu.icon.fill};
        width: 20px;
        height: 20px;
        margin-right: 20px;
    `

    return <LinkElement 
        to={navLink.to} 
        scrollTo={navLink.scrollTo} 
        external={navLink.type === 'external'} 
        css={itemStyle}
    >
        <NavIcon icon={navLink.icon} css={iconStyle} />
        <div>{navLink.text}</div>
    </LinkElement>
}