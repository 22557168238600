/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC, ReactNode } from "react"
import { useTheme } from '../ThemeSelector'

type ListItemProps = {
    children: ReactNode
    onClick?: () => void
}


export const ListItem: FC<ListItemProps> = ({ children, onClick }) => {

    const isClickable = Boolean(onClick)
    const theme = useTheme()
    
    const containerStyle = css`
        padding: 15px 0;
        border-bottom: 1px solid ${theme.list.item.borderColor};
        display: flex;
        width: 100%;
        ${isClickable && css`
            &:hover {
                cursor: pointer;
            }
        `}

    `
    return <div css={containerStyle} onClick={onClick}>{ children }</div>
}