/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC } from 'react'
import { LinkButton } from '../common/LinkButton'
import { navLinks } from '../common/navigation/navLinks'
import banner from "./banner.png"

let bannerAlignment: 'center' | 'left' = 'left'

export const Home: FC = () => {

    const defaultNavItem = navLinks.agenda


    const containerStyle = css`
        flex: 1 1 auto;
        display: flex;
        width: 100%;
        margin: 20px;
    `

    const homeStyle = css`
        width: 100%;
        max-width: ${bannerAlignment === 'left' ? '250px' : '400px'};
        margin: auto;
        padding: 20px 0 80px;
        text-align: ${bannerAlignment === 'left' ? 'left' : 'center'};
    `

    const bannerStyle = css`
        width: ${bannerAlignment === 'left' ? '100%' : '90%'};
        max-width: 250px;
        display: block;
        margin: 0 auto 30px;
    `

    const buttonStyle = css`
        margin-top: 20px;
    `

    return <div css={containerStyle}>
        <div css={homeStyle}>
            <img src={banner} alt="" css={bannerStyle} />
            <LinkButton to={defaultNavItem.to} css={buttonStyle}>Enter &gt;</LinkButton>

        </div>
    </div>
}