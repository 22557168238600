/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC } from "react"
import parse from "html-react-parser"
import { H3 } from '../common/typography'
import { getFullName } from './SpeakerSummary'
import { useTheme } from '../ThemeSelector'
import { Avatar } from './Avatar'
import { Contact, FlatEpSessionWithLuxonTimes, getDates } from '../common/event-content/useEventContentFetch'
import { useEventContentContext } from '../common/event-content/EventContentContext'
import { ListItem } from '../common/ListItem'
import { usePopupContext } from '../common/popup/PopupContext'
import { SessionSummary } from '../agenda/SessionSummary'
import { AgendaDateHeading } from '../agenda/AgendaDateHeading'
type SpeakerPopupProps = {
    speaker: Contact
}


export const SpeakerPopup: FC<SpeakerPopupProps> = ({
    speaker
}) => {

    const theme = useTheme()

    const containerStyle = css`
        text-align: center;
    `

    const avatarStyle = css`
        margin: 0 auto 20px;
        width: 100px;
        height: 100px;
    `

    const headingStyle = css`
        text-align: center; 
        color: ${theme.popup.heading.color};
    `

    const jobStyle = css`
        text-align: center;
        margin: 10px 0 40px;
        color: ${theme.popup.color};
    `

    const bioStyle = css`
        text-align: left;
        color: ${theme.popup.color};
    `

    const { findSession } = useEventContentContext()
    const { showPopup } = usePopupContext()

    const sessions = speaker.sessions.map(session => findSession(session.entity_token) as FlatEpSessionWithLuxonTimes)
    const dates = getDates(sessions)

    return <div css={containerStyle}>
        <Avatar
            imageUrl={speaker.profile_photo_cropped}
            css={avatarStyle}
        />
        <H3 css={headingStyle}>{ getFullName(speaker) }</H3>
        <div css={jobStyle}>{ speaker.job_title }{(speaker.job_title && speaker.company) && ', '}{ speaker.company }</div>
        <div css={bioStyle}>{ parse(speaker.bio) }</div>

        <div>
            {
                dates.map(date => <div
                    key={date.datestring}
                >
                    <AgendaDateHeading
                        activeDate={date.datestring}
                        dates={dates}
                    />
                    { sessions
                        .filter(session => session.day === date.datestring)
                        .map(session => <ListItem
                            key={session.entity_token}
                            onClick={() => showPopup({
                                data: session,
                                type: "session"
                            })}
                        >
                            <SessionSummary isClickable={session.isClickable} session={session} />
                        </ListItem>)
                    }
                </div>)
            }
            
        </div>
    </div>
}