import axios from "axios"
import { eventDetails } from "../eventDetails"

const { clientId, eventId, eventTitle } = eventDetails

export const networkingApi = {
    sendMessage: (fromUserToken: string, toId: string, message: string) => axios
        .post('https://mw.marketpartner.com/networking-mailer/', {
            eventId,
            clientId,
            eventName: eventTitle,
            fromUserToken,
            toId,
            message
        })
        .then(res => res.data)
}