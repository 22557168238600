/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { DynamicFormElements, useUpdate, useDynamicFormState, DynamicFormState } from "@marketpartner/mp-common-react"
import { Form, FormStatus, formStatus, PublicForm } from "@marketpartner/backend-api"
import { FC, useState, useEffect } from "react"
import { LoadingSpinner } from "../loading/LoadingSpinner"
import { dynamicElements } from "./fields/dynamicElements"
import { P } from '../typography'
import { scrollTo } from '../utility-functions/scrollTo'
import { Button } from '../Button'
import { useTheme } from '../../ThemeSelector'

type DynamicFormProps = {
    onSubmit: (formState: DynamicFormState) => Promise<unknown>
    form: Form | PublicForm
    submitButtonValue?: string
    successMessage?: string
    onSuccess?: (response: any) => void
}

export const DynamicForm: FC<DynamicFormProps> = ({
    onSubmit, 
    form, 
    submitButtonValue, 
    successMessage,
    onSuccess
}) => {

    const [formError, setFormError] = useState<string>()
    const [formSubmitted, setFormSubmitted] = useState(false)
    const formState = useDynamicFormState(form.elements, 'complete')
    const status = formStatus(form)

    useEffect(() => {
        if (formState.invalidFields.length === 0) {
            setFormError(undefined)
        }
    }, [formState.invalidFields.length])

    const submitFormRequest = useUpdate({
        update: () => {
            setFormError(undefined)
            setFormSubmitted(false)
            if (formState.invalidFields.length) {
                const error = new Error()
                error.message = "Invalid fields"
                throw error
            }
            return onSubmit(formState)
        },
        onSuccess: (response) => {
            setFormSubmitted(true)
            scrollTo('form-container')
            if (onSuccess) {
                onSuccess(response)
            }
            formState.reset()
        },
        onError: (error: any) => {
            scrollTo('form-container')
            if ('message' in error) {
                setFormError(error.message)
            } else {
                setFormError("An error occurred")
            }
        }
    })

    const theme = useTheme()

    const containerStyle = css`
        padding-top: 20px;
        ${(formError && formState.invalidFields.length > 0) && css`
            & .field-error {
                display: initial !important;
            }
        `}
    `

    return <div id="form-container"  css={containerStyle}>
        { status === FormStatus.Active
            && <>
                {submitFormRequest.isLoading && <LoadingSpinner 
                    absolute={true} 
                />}
                {formError && <P 
                    css={css`color: ${theme.form.message.error.color};`}
                >{formError}</P>}
                {formSubmitted && <P 
                    css={css`color: ${theme.form.message.success.color};`}
                >{successMessage}</P>}
                <DynamicFormElements 
                    state={formState} 
                    elementComponents={dynamicElements} 
                />
                <Button 
                    css={css`width: 100%; margin-top: 20px;`} 
                    onClick={submitFormRequest.trigger}
                >{submitButtonValue ?? "Submit" }</Button>
            </>
        }
        { status === FormStatus.Closed && <div css={css`color: ${theme.form.message.success.color};`}>This form is now closed</div> }
        { status === FormStatus.Scheduled && <div css={css`color: ${theme.form.message.success.color};`}>This form will be available later</div> }
    </div>
}