/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC } from "react"
import { ListItem } from "../common/ListItem"
import { usePopupContext } from '../common/popup/PopupContext'
import { ReactComponent as InfoIcon } from '../agenda/info-icon.svg'
import { ReactComponent as WebIcon } from './web-icon.svg'
import { useTheme } from "../ThemeSelector"
import { SponsorLogo } from './SponsorLogo'
import { Company } from '../common/event-content/useEventContentFetch'

type SponsorRowProps = {
    sponsor: Company
}

export const SponsorRow: FC<SponsorRowProps> = ({
    sponsor
}) => {

    const theme = useTheme()

    const containerStyle = css`
        width: 100%;
    `

    const topRowStyle = css`
        width: 100%;
        display: flex;
        justify-content: space-between;
        color: ${theme.list.item.color};
    `

    const categoryStyle = css`
        color: ${theme.list.item.colorAccent};
        font-size: 15px;
    `

    const actionsStyle = css`
        align-items: center;
        display: flex;
    `

    const iconStyle = css`
        height: 15px; 
        width: 15px;
        margin-right: 5px;
        transition: fill .1s;
        fill: ${theme.list.item.action.fill};
    `

    const detailsStyle = css`
        flex: 1 1 auto;
        color: ${theme.list.item.color};
        margin-top: 10px;
        padding-right: 10px;
    `

    const logoStyle = css`
        margin: 5px 0;
    `

    const chevronStyle = css`
        border-style: solid;
        border-width: 1px 1px 0 0;
        border-color: ${theme.list.item.chevron.color};
        display: inline-block;
        height: 6px;
        transform: rotate(45deg);
        margin-right: 10px;
        width: 6px;
        transition: border-color .1s;
    `

    const { showPopup } = usePopupContext()

    function onShowPopup() {
        showPopup({
            type: "sponsor",
            data: sponsor
        })
    }

    return <ListItem
        onClick={sponsor.isClickable ? onShowPopup : undefined}
    >
        <div css={containerStyle}>
            { sponsor.isClickable && 
                <div css={topRowStyle}>
                    <div css={categoryStyle}>
                        { sponsor.categoryTitle }
                    </div>
                    <div css={actionsStyle} className="actions">
                        { sponsor.profile && <InfoIcon className="icon" css={iconStyle} />}
                        { sponsor.website && <WebIcon className="icon" css={iconStyle} />}
                        <div className="chevron" css={chevronStyle} />
                    </div>
                </div>
            }
            <div css={detailsStyle}>
                <SponsorLogo 
                    src={sponsor.logo_cropped} 
                    width="150px" 
                    css={logoStyle}
                />
            </div>
            
        </div>
    </ListItem>
}