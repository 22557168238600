/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useTheme } from '../../ThemeSelector'
import { SectionContent } from '../section/SectionContent'
import { Tabs } from '../tabs/Tabs'
import { breakpoint2 } from '../ViewportContext'
import { SinglePage, TabbedPage } from './pages'
import { PageTitle } from './PageTitle'

type PageRendererProps = {
    page: SinglePage | TabbedPage
}

export const PageRenderer: FC<PageRendererProps> = ({
    page
}) => {
    
    const theme = useTheme()

    const containerStyle = css`
        flex: 1 1 auto;
        display: flex;
        width: 100%;
        justify-content: center;
        max-width: 700px;
    `

    const innerStyle = css`
        width: 100%;
        max-width: 600px;
        padding: 15px 0 0;
        display: flex;
        flex-direction: column;
    `
    const canvasStyle = css`
        width: 100%;
        flex: 1 1 auto;
        background: ${theme.app.canvas.backgroundColor};
        border-radius: 5px 5px 0 0;
        color: black;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: ${breakpoint2}px) {
            border-radius: 0;
        }
    `
    return <div css={containerStyle}>
        <div css={innerStyle}>

            <PageTitle title={page.title} />

            <div css={canvasStyle}>

                { page.type === 'tabbed-page' && <Tabs tabs={page.tabs} /> }

                <SectionContent>
                    { page.type === "single-page"
                        ? page.element 
                        : <Routes>
                            { page.tabs.map(tab => <Route 
                                key={tab.path} 
                                path={tab.path} 
                                element={tab.element} 
                            />)}
                            <Route path="*" element={<Navigate replace to={page.tabs[0].path} />} />
                        </Routes>
                    }
                </SectionContent>
                
            </div>
        </div>
    </div>

}