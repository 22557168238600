import { FC } from "react"
import { Contact, ContentCategory } from "../common/event-content/useEventContentFetch"
import { SpeakerRow } from "./SpeakerRow"

type LoadedSpeakersProps = {
    speakers: Contact[]
    categories: ContentCategory[]
}

export const LoadedSpeakers: FC<LoadedSpeakersProps> = ({
    speakers
}) => {
    return <>
        { speakers.map(speaker => <SpeakerRow
            key={speaker.entity_token} 
            speaker={speaker}
        />)}
    </>
}
