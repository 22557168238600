/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { ReactComponent as Menu } from "./menu.svg"
import { ReactComponent as Close } from "./close.svg"
import { NavIcon } from './NavIcon'
import { LinkElement } from '../LinkElement'
import { FC, ReactNode } from "react"
import { useTheme } from '../../ThemeSelector'
import { navLinks } from './navLinks'
type NavBarProps = {
    navOpen: boolean
    setNavOpen: (value: boolean) => void
}

export const NavBar: FC<NavBarProps> = ({ navOpen, setNavOpen }) => {

    const theme = useTheme()

    const navStyle = css`
        position: fixed;
        bottom: 0;
        background: ${theme.nav.dock.backgroundColor};
        width: 100%;
        height: 80px;
        padding-top: 10px;
        z-index: 2;
    `

    const containerStyle = css`
        display: flex;
        justify-content: space-between;
        align-content: center;
        max-width: 350px;
        padding: 0 10px;
        margin: 0 auto;
    `

    const iconStyle = css`
        fill: ${theme.nav.dock.icon.fill};
        width: 25px;
        height: 25px;
        margin: 0 10px;
    `

    function toggleMenu() {
        setNavOpen(!navOpen)
    }

    const menuItems = [navLinks.about, navLinks.agendaSpeakers, navLinks.questionsFeedback, navLinks.pollingWordcloud, navLinks.networking]

    return <div css={navStyle}>
        <div css={containerStyle}>

            { navOpen ?
                <Close css={iconStyle} onClick={toggleMenu} /> :
                <Menu css={iconStyle} onClick={toggleMenu} />
            }

            { menuItems
                .map(item => <NavBarItem 
                    key={item.to} 
                    to={item.to} 
                    icon={item.icon} 
                    scrollTo={item.scrollTo} 
                />)
            }
        </div>
    </div>
}

type NavBarItemProps = {
    className?: string
    to: string
    external?: boolean
    icon: ReactNode
    scrollTo?: string
}
const NavBarItem: FC<NavBarItemProps> = ({ external, to, icon, scrollTo, ...props}) => {

    const theme = useTheme()

    const iconStyle = css`
        fill: ${theme.nav.dock.icon.fill};
        width: 25px;
        height: 25px;
        margin: 0 10px;
    `

    const linkStyle = css`
        &.is_selected > svg {
            fill: ${theme.nav.dock.icon.fillActive}
        }
    `

    return <LinkElement
        to={to}
        scrollTo={scrollTo}
        css={linkStyle}
        external={external}
        {...props}
    ><NavIcon icon={icon} css={iconStyle} /></LinkElement>
}