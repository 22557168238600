/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC } from 'react'
import { LinkElement } from '../LinkElement'
import { useTheme } from '../../ThemeSelector'
import { NavItems } from './NavItems'
import { ReactComponent as Logo } from "./nav-logo.svg"

export const MobileNav: FC = () => {

    const theme = useTheme()

    const backgroundStyle = css`
        background: ${theme.nav.menu.mobile.backgroundColor};
        color: ${theme.nav.menu.color};
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 80px;
        z-index: 1;
    `

    const containerStyle = css`
        padding: 40px 20px;
        max-width: 400px;
        margin: 0 auto;
    `

    const logoLinkStyle = css`
        width: 100%;
        display: flex;
    `

    const logoStyle = css`
        width: 90%;
        max-width: 180px;
        margin: 0 auto 30px;
        fill: ${theme.nav.menu.icon.fill}
    `

    return <div css={backgroundStyle}>
        <div css={containerStyle}>
            <LinkElement css={logoLinkStyle} to=""><Logo css={logoStyle} /></LinkElement>
            <NavItems />
        </div>
    </div>
}
