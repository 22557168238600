import { cloneElement } from "../utility-functions/cloneElement"
import { FC, ReactNode } from "react"
type NavIconProps = {
    icon: ReactNode
    className?: string
}

export const NavIcon: FC<NavIconProps> = ({ icon, className }) => {
    if (icon) {
        return cloneElement(icon, {className: className} )
    }
    return <></>
}