import { ElementComponents } from "@marketpartner/mp-common-react";
import { DynamicTextarea } from "./Textarea";
import { DynamicText } from "./Text";
import { DynamicRadioButtons } from "./RadioButtons";
import { DynamicTextField } from "./TextField";
import { DynamicEmailField } from "./EmailField";
import { DynamicSelect } from "./Select";
import { DynamicCheckboxes } from "./Checkboxes";
import { HiddenField } from "./HiddenField";

export const dynamicElements: ElementComponents = {
    Text: DynamicText,
    TextField: DynamicTextField,
    EmailField: DynamicEmailField,
    Checkboxes: DynamicCheckboxes,
    Select: DynamicSelect,
    Textarea: DynamicTextarea,
    HiddenField: HiddenField,
    MultiUpload: undefined!,
    RadioButtons: DynamicRadioButtons,
    Signature: undefined!,
    Upload: undefined!,
    UrlParameter: undefined!
}