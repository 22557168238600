import { FC } from "react"
import { Contact } from "../common/event-content/useEventContentFetch"
import { ListItem } from '../common/ListItem'
import { usePopupContext } from "../common/popup/PopupContext"
import { SpeakerSummary } from './SpeakerSummary'

export const SpeakerRow: FC<{ speaker: Contact }> = ({
    speaker 
}) => {

    const { showPopup } = usePopupContext()

    function onShowPopup() {
        showPopup({
            type: "speaker",
            data: speaker
        })
    }

    return <ListItem
        onClick={speaker.isClickable ? onShowPopup : undefined}
    >
        <SpeakerSummary 
            speaker={speaker}
            isClickable={speaker.isClickable}
        />
    </ListItem>
}

