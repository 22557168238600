/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC } from 'react'
import { LinkElement } from '../LinkElement'
import { useTheme } from '../../ThemeSelector'
import { ReactComponent as NavLogo } from "./nav-logo.svg"
import { NavItems } from './NavItems'

export const DesktopNav: FC = () => {

    const theme = useTheme()

    const bgStyle = css`
        background: ${theme.nav.menu.desktop.backgroundColor};
        color: ${theme.nav.menu.color};
        width: 40%;
        max-width: 600px;
        height: 100%;
        overflow: auto;
        display: flex;
        justify-content: flex-end;
    `

    const containerStyle = css`
        width: 100%;
        max-width: 340px;
        padding: 80px 20px 40px;
    `

    const logoStyle = css`
        fill: ${theme.nav.menu.icon.fill};
        width: 150px;
        margin-bottom: 40px;
        display: block;
    `

    

    return <div css={bgStyle}>
        <div css={containerStyle}>
            <LinkElement to=""><NavLogo css={logoStyle} /></LinkElement>
            <NavItems />
        </div>
    </div>
}
