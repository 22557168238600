import { Delegate } from "@marketpartner/backend-api"
import { FC } from "react"
import { Loading } from "../common/loading/Loading"
import { LoadedDelegates } from "./LoadedDelegates"
import { useFetchDelegates } from "./useFetchDelegates"

export type DelegateWithFields = Delegate & {
    company: string
    jobTitle: string
}

export const Delegates: FC = () => {

    const delegatesFetch = useFetchDelegates()
    return <Loading
        request={delegatesFetch}
        render={delegates => <LoadedDelegates
            delegates={delegates}
        />}
    />
}