/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { NetworkingProfile } from "@marketpartner/backend-api"
import { useUpdate } from '@marketpartner/mp-common-react'
import { FC, useState } from "react"
import { useAuthenticationContext } from '../common/authentication/AuthenticationContext'
import { Button } from '../common/Button'
import { Textarea } from '../common/form/fields/Textarea'
import { LoadingSpinner } from '../common/loading/LoadingSpinner'
import { H3, P } from '../common/typography'
import { useTheme } from '../ThemeSelector'
import { networkingApi } from './networkingApi'
type NetworkingUserPopupProps = {
    user: NetworkingProfile
    isMessageable: boolean
}
export const NetworkingUserPopup: FC<NetworkingUserPopupProps> = ({
    user,
    isMessageable
}) => {
    const theme = useTheme()

    const containerStyle = css`
        text-align: center;
    `

    const headingStyle = css`
        text-align: center; 
        color: ${theme.popup.heading.color};
    `

    const jobStyle = css`
        text-align: center;
        margin: 10px 0 40px;
        color: ${theme.popup.color};
    `

    return <div css={containerStyle}>

        <H3 css={headingStyle}>{ user.firstName } { user.lastName }</H3>
        <div css={jobStyle}>{ user.jobTitle }{(user.jobTitle && user.company) && ', '}{ user.company }</div>

        { isMessageable && <MessageForm 
            user={user}
            isMessageable={isMessageable}
        />}

    </div>
}

type MessageFormProps = {
    user: NetworkingProfile
    isMessageable: boolean
}
export const MessageForm: FC<MessageFormProps> = ({
    isMessageable,
    user
}) => {
    const theme = useTheme()
    const [message, setMessage] = useState('')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')

    const { mpt } = useAuthenticationContext()

    const sendMessageRequest = useUpdate({
        update: () => {
            setError('')
            setSuccess('')
            return networkingApi.sendMessage(mpt, user.registrationId, message)
        },
        onError: (error: any) => setError('message' in error ? error.message : 'An error occurred'),
        onSuccess: () => {
            setMessage('')
            setSuccess('Your message has been sent.')
        }
    })
    return <div>
        { sendMessageRequest.isLoading && <LoadingSpinner absolute={true} />}
        { error && <P css={css`color: ${theme.form.message.error.color}; text-align: center;`}>{ error }</P>}
        { success && <P css={css`color: ${theme.form.message.success.color}; text-align: center;`}>{ success }</P>}
        { isMessageable && <>
            <Textarea
                css={css`text-align: center;`}
                label={`Send a message to ${user.firstName}`}
                placeholder="Enter message"
                required={true}
                value={message}
                setValue={setMessage}
            />
            <Button
                onClick={sendMessageRequest.trigger}
                disabled={!Boolean(message)}
                css={css`width: 100%;`}
            >Submit message</Button>
        </>}
    </div>
}