/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC } from 'react'
import { useTheme } from '../ThemeSelector'


type PollHeadingsProps = {
    heading: string | undefined
    subheading: string | undefined
}



export const PollHeadings: FC<PollHeadingsProps> = ({
    heading,
    subheading
}) => {

    const theme = useTheme()

    const headingStyle = css`
        margin: 0 0 10px;
        text-align: center;
        font-weight: 500;
        color: ${theme.polling.color};
    `
    const subheadingStyle = css`
        margin: 0 0 10px;
        text-align: center;
        font-weight: 500;
        color: ${theme.polling.color};
    `

    return <>
        {heading && <h3 css={headingStyle}>
            {heading}
        </h3>}
        {subheading && <h3 css={subheadingStyle}>
            {subheading}
        </h3>}
    </>
}