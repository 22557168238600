/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC } from "react"
import { useTheme } from '../ThemeSelector'

const iframeStyle = css`
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
`

export const Captions:FC = () => {

    const theme = useTheme()

    const iframeUrl = `https://attend.wordly.ai/frame/PBQL-9543?enableTTS=true&bgcolor=${theme.app.canvas.backgroundColor.replace("#","")}&fgcolor=${theme.app.text.color.replace("#","")}&speakerLanguage=false&fgsize=18px`

    return <iframe css={iframeStyle} src={iframeUrl} title="Captions" width="100%" />

}