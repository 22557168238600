/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { FC } from "react"
import { useTheme } from '../ThemeSelector'
import { Date } from '../common/event-content/useEventContentFetch'

type AgendaDateTabsProps = {
    dates: Date[]
    activeDate: string
    setActiveDate: (newActiveDate: string) => void
}

export const AgendaDateTabs: FC<AgendaDateTabsProps> = ({ 
    dates, 
    activeDate, 
    setActiveDate 
}) => {
    const TabsStyle = css`
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        padding: 15px 0;
        gap: 10px;
    `

    return <div css={TabsStyle}>
        {dates.map(date => <AgendaDateTab 
            key={date.datestring}
            date={date}
            activeDate={activeDate}
            setActiveDate={setActiveDate}
        />)}
    </div>
}

type AgendaDateTabProps = {
    date: Date
    activeDate: string
    setActiveDate: (newActiveDate: string) => void
}
const AgendaDateTab: FC<AgendaDateTabProps> = ({ 
    date, 
    activeDate, 
    setActiveDate 
}) => {

    const handleClick = () => {
        setActiveDate(date.datestring)
    }

    const theme = useTheme()

    const tabStyle = css`
        flex: 1 1 auto;
        flex-grow: 0;
        padding: 0 5px;
        cursor: pointer;
        color: ${theme.agenda.date.tabs.color};
        ${activeDate === date.datestring && css`
            border-bottom: 2px solid ${theme.agenda.date.tabs.borderColor};
        `}
    `

    return <div 
        onClick={handleClick} 
        css={tabStyle}
    >
        {date.datetime.toFormat("LLL d")}
    </div>
}