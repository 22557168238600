import { useFetch, DynamicFormState } from "@marketpartner/mp-common-react"
import { Form, PublicForm } from "@marketpartner/backend-api"
import { FC } from "react"
import { DynamicForm } from "../common/form/DynamicForm"
import { useBackend } from "../common/hooks/useBackend"
import { Loading } from "../common/loading/Loading"
import { eventDetails } from "../eventDetails"

const {clientId, eventId} = eventDetails

export const Feedback = () => {

    const backend = useBackend()

    const feedbackFormRequest = useFetch( async () => {
        const forms = await backend.forms().getAll(clientId, eventId)
        const feedbackForm = forms.find(form => form.name === 'Feedback')
        if (!feedbackForm) {
            const error = new Error("Feedback form not set up")
            throw error
        }
        return feedbackForm
    }, [])

    return <Loading request={feedbackFormRequest} render={data => <>
        <LoadedFeedback form={data} />
    </>} />
}

type LoadedFeedbackProps = {
    form: Form | PublicForm
}

const LoadedFeedback: FC<LoadedFeedbackProps> = ({form}) => {

    const backend = useBackend()

    const onSubmit = (formState: DynamicFormState) => {
        return backend.forms().responses().insert(clientId, eventId, form.id, {
            formData: formState.values
        })
    }

    return <DynamicForm onSubmit={onSubmit} form={form} submitButtonValue="Submit" successMessage="Thank you for submitting your feedback." />
}