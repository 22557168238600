import { FC, useEffect } from "react"
import { useEventContentContext } from "../common/event-content/EventContentContext"
import { Loading } from "../common/loading/Loading"
import { LoadedSponsors } from "./LoadedSponsors"

export const Sponsors:FC = () => {

    const { eventContentRequest } = useEventContentContext()

    useEffect(() => {
        eventContentRequest.reload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // eslint-disable-next-line react/jsx-no-undef
    return <Loading
        request={eventContentRequest} 
        render={({sponsorsData}) => 
            <LoadedSponsors 
                sponsors={sponsorsData.sponsors} 
                categories={sponsorsData.categories} 
            />
        }
        reloadMode="show-old-data"
    />
}