import axios from 'axios';
import { FetchRequest, useFetch } from '@marketpartner/mp-common-react';
import Papa from 'papaparse';

export type Delegate = {
    firstName: string;
    lastName: string;
    company: string;
};

export const useFetchDelegates = (): FetchRequest<Delegate[] | null> => {

    return useFetch(async (): Promise<Delegate[] | null> => {
        try {
            const response = await axios.get('delegates.csv'); // Replace with the actual path to your CSV file

            const parsedDelegates: Delegate[] = await new Promise((resolve, reject) => {
                Papa.parse(response.data, {
                    header: true,
                    complete: (results) => {
                        const delegates: Delegate[] = results.data.map((row: any) => ({
                            firstName: row['First Name'],
                            lastName: row['Last Name'],
                            company: row['Company'],
                        }));
                        resolve(delegates);
                    },
                    error: (error) => {
                        reject(error);
                    },
                });
            });
            return parsedDelegates;

        } catch (error) {
            console.error('Error fetching delegates:', error);
            return null;
        }
    }, [])
};