/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC } from "react"
import { useTheme } from '../ThemeSelector'
import { ReactComponent as SpeakersIcon } from '../speakers/speakers-icon.svg'
import { ReactComponent as InfoIcon } from './info-icon.svg'
import { FlatEpSessionWithLuxonTimes } from '../common/event-content/useEventContentFetch'

type SessionSummaryProps = {
    session: FlatEpSessionWithLuxonTimes
    isClickable: boolean
}

export const SessionSummary: FC<SessionSummaryProps> = ({ 
    session, 
    isClickable 
}) => {

    const theme = useTheme()

    const containerStyle = css`
        width: 100%;
        text-align: left;
    `

    const topRowStyle = css`
        width: 100%;
        display: flex;
        justify-content: space-between;
        color: ${theme.list.item.color};
    `

    const timesStyle = css`
        
    `

    const actionsStyle = css`
        align-items: center;
        display: flex;
    `

    const iconStyle = css`
        height: 15px; 
        width: 15px;
        margin-right: 5px;
        transition: fill .1s;
        fill: ${theme.list.item.action.fill};
    `

    const highlightedTime = css`
        color: ${theme.list.item.colorAccent};
        padding-right: 5px;
    `

    const detailsStyle = css`
        flex: 1 1 auto;
        color: ${theme.list.item.color};
        margin-top: 10px;
        padding-right: 10px;
    `

    const nameStyle = css`
        font-weight: 500;
    `

    const chevronStyle = css`
        border-style: solid;
        border-width: 1px 1px 0 0;
        border-color: ${theme.list.item.chevron.color};
        display: inline-block;
        height: 6px;
        transform: rotate(45deg);
        margin-right: 10px;
        width: 6px;
        transition: border-color .1s;
    `

    const start = session.start.toFormat('HH:mm').toLowerCase()
    const end = session.end.toFormat('HH:mm').toLowerCase()

    return <div css={containerStyle}>
        <div css={topRowStyle}>
            <div css={timesStyle}>
                <span css={highlightedTime}>{ start }</span>
                { start !== end && end}
            </div>
            { isClickable && <div css={actionsStyle} className="actions">
                { session.speakers.length > 0 && <SpeakersIcon className="icon" css={iconStyle} />}
                { session.synopsis && <InfoIcon className="icon" css={iconStyle} />}
                <div className="chevron" css={chevronStyle} />
            </div> }
        </div>
        <div css={detailsStyle}>
            <div css={nameStyle}>
                { session.name }
            </div>
        </div>
        
    </div>
}