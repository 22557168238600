import { axiosWithAuth, BackendApi } from "@marketpartner/backend-api";
import { useMemo } from "react";
import { getEnvironment } from "../utility-functions/getEnvironment";
import { useTokenContext } from "../authentication/TokenContext";

export const useBackend = () => {

    const accessToken = useTokenContext().mpt

    return useMemo(() => {
        const axios = axiosWithAuth(() => accessToken ? `token ${accessToken}` : "Anonymous")
        axios.defaults.baseURL = getBaseUrl()
        return new BackendApi(axios)
    }, [accessToken])
    
}

function getBaseUrl() {
    if (getEnvironment() === "dev") {
        return 'https://dev.marketpartner.app'
    }
    return 'https://marketpartner.app'
}
