import { Sponsors } from "../../sponsors/Sponsors"
import { Feedback } from "../../feedback/Feedback"
import { ReactNode } from "react"
import { Delegates } from "../../delegates/Delegates"
import { NetworkingSignUp } from "../../networking/NetoworkingSignUp"
import { Agenda } from "../../agenda/Agenda"
import { Speakers } from "../../speakers/Speakers"
import { Polling } from "../../polling/Polling"
import { Questions } from "../../questions/Questions"
import { Captions } from "../../captions/Captions"
import { About } from "../../about/About"
import { Resources } from "../../resources/Resources"
import { WordCloud } from "../../word-cloud/WordCloud"

type Tab = {
    label: string
    path: string
    element: ReactNode
}
export type SinglePage = {
    type: "single-page"
    title: string
    element: ReactNode
    path: string
}
export type TabbedPage = {
    type: "tabbed-page"
    title: string
    path: string
    tabs: Tab[]
}

export const pages: Array<SinglePage | TabbedPage> = [
    {
        type: "single-page",
        title: "Need to know",
        path: "/about",
        element: <About/>
    },
    {
        type: "tabbed-page",
        title: "Agenda & speakers",
        path: "/agenda-speakers/*",
        tabs: [
            { label: 'Agenda', path: 'agenda', element: <Agenda />},
            { label: 'Speakers', path: 'speakers', element: <Speakers />}
        ]
    },
    {
        type: "tabbed-page",
        title: "Live polling",
        path: "/polling/*",
        tabs: [
            { label: 'Polling', path: 'polling', element: <Polling />},
            { label: 'Word cloud', path: 'wordcloud', element: <WordCloud />}
        ]
    },
    {
        type: "single-page",
        title: "Captions",
        path: "/captions",
        element: <Captions />
    },
    {
        type: "single-page",
        title: "Delegates",
        path: "/delegates",
        element: <Delegates />
    },
    {
        type: "single-page",
        title: "Sponsors",
        path: "/sponsors",
        element: <Sponsors />
    },
    {
        type: "single-page",
        title: "Networking",
        path: "/networking",
        element: <NetworkingSignUp />
    },
    {
        type: "single-page",
        title: "Resources",
        path: "/resources",
        element: <Resources />
    },
    {
        type: "tabbed-page",
        title: "Questions & feedback",
        path: "/questions-feedback/*",
        tabs: [
            { label: 'Questions', path: 'questions', element: <Questions />},
            { label: 'Feedback & comments', path: 'feedback', element: <Feedback />}
        ]
    }
]
